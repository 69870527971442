<template>
    <v-btn
        style="margin:2px"
        :ripple="false"
        outlined
        class="p-color-secondary"
        small
        @click="$emit('click')"
    >
        <v-icon small>{{icon}}</v-icon>
    </v-btn>
</template>
<script>
export default {
    props: {
        icon: String
    }
}
</script>