<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_9.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home__body-container">
                <div>
                    <OFillInfoComp
                        v-for="(info, index) in listInfo"
                        :key="index"
                        :info="info"
                        class="space-bottom"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OFillInfoComp
} from "../../components/public/organisms";
export default {
    components: {
        OFillInfoComp
    },
    data: () => ({
        listInfo: [
            {
                img: "/imgs/ferias_publicidad/banners_publicitarios.png",
                title: "Roll - Up",
                listText: [
                    `Los roll - up son una forma muy eficiente de abaratar costos
                    y llamar la atención de los potenciales clientes sin invertir
                    demasiado, garantiza un gran éxito en cualquier proyecto.`,
                    `Los banners roll up puede aportar o exponer datos, slogans,
                    números de teléfono o direcciones toda la información que se 
                    desee, pero de manera condensada y atractiva.`
                ]
            }, {
                img: "/imgs/ferias_publicidad/feria.png",
                title: "Ferias",
                listText: [
                    `Los productos de cartelería cumplen con una función muy
                    importante en los eventos. Es por ello que la impresión de
                    esta debe ser de alta calidad, puede ser importante contar
                    con materiales gráficos en los que puedes mostrar tus productos
                    o servicios de forma visual y atractiva.`,
                    `La participación en ferias y eventos es esencial para aumentar
                    su visibilidad, encontrar nuevos clientes y observar a la competencia. `
                ]
            }, {
                img: "/imgs/ferias_publicidad/revistas.png",
                title: "Revistas",
                listText: [
                    `La revista es una herramienta de comunicación utilizada con
                    frecuencia por los profesionales, contiene publicidad en las
                    revistas ofrece un medio gráfico de mayor calidad sus colores
                    brillantes, sus imágenes definidas, redacciones impecables,
                    puede tener numerosos usos, las revistas pueden usarse para
                    presentar una gama de productos a tus potenciales clientes. `
                ]
            }, {
                img: "/imgs/ferias_publicidad/tarjetas.png",
                title: "Tarjetas",
                listText: [
                    `La tarjeta de presentación es una herramienta fundamental en
                    el marketing personal y de tu empresa, ayudan a las personas a
                    recordar mejor nuestro nombre y asociarlo con nuestra marca y
                    todos los elementos que la rodean como el logo y nuestros colores
                    corporativos, ayudan a generar oportunidades de negocios.`
                ]
            }, {
                img: "/imgs/ferias_publicidad/triptico_dipticos.png",
                title: "Trípticos y Dípticos",
                listText: [
                    `Los trípticos son los folletos informativos que pueden doblarse
                    en partes, promociona un producto un evento aportando información
                    breve y consolida la imagen de su empresa.`,
                    `El contenido principal del folleto debe ser conciso y fácil de
                    leer, debes asegurarte de que tu información de contacto esté
                    incluida. No hay nada peor que un cliente potencial esté interesado
                    pero que no pueda conocer más de tu negocio.`
                ]
            }
        ]
    })
}
</script>