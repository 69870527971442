<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_1.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="home__body space-top space-bottom">
            <div class="home__body-container mission-vision__body">
                <div class="mission-vision__img">
                    <v-img
                        src="/imgs/mission_vision/valores.jpg"
                        contain
                    ></v-img>
                </div>
                <div>
                    <div class="d-flex align-center">
                        <h1 class="app-public-big-title">Nuestros Valores</h1>
                    </div>
                    <h1 class="app-public-subtitle mt-1">
                        Los valores sobre los que se asienta la cultura de nuestra empresa adoptamos los siguientes valores para el logro de la satisfacción de nuestros clientes:
                    </h1>
                    <div class="ml-7 mt-2">
                        <h1 class="app-public-subtitle">Respeto</h1>
                        <h1 class="app-public-subtitle">Honestidad e integridad</h1>
                        <h1 class="app-public-subtitle">Creatividad</h1>
                        <h1 class="app-public-subtitle">Trabajo en equipo</h1>
                        <h1 class="app-public-subtitle">Cultura enfocada en la calidad</h1>
                    </div>
                    <div class="d-flex align-center space-top">
                        <h1 class="app-public-big-title">Nuestros Objetivos</h1>
                    </div>
                    <h1 class="app-public-subtitle mt-1">
                        Somos una empresa de servicios de impresión abarca todo el proceso, desde el diseño o la maquetación pasando por la impresión, el acabado y la distribución.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>