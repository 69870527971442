export default {
    data: () => ({
        dialogLoaderOperation: false,
        //sms loader data
        dialogLoaderData: false,
        //sms confirmacion
        dialogConfirmation: false,
        smsConfirmation: [],
        //sms error
        withoutAccess: {
            toLogin: false,
            withoutAutorization: false
        },
        dialogError: false,
        smsError: [],
        actionError: false,
        //sms correcto
        smsAction: [],
        frameAction: false,
        colorMessage: 'success',
        timeSuccess: 3000,
        //data responsive
        isMobile: false,
        //paginacion
        currentPage: 0,
        lastPage: 0,
        total: 0
    }),
    methods: {
        mDGResetearSmsAction() {
            clearTimeout(this.timeSuccess);
            this.frameAction = false;
        },
        mDGShowSmsAction(smsAction = [], color = "success") {
            this.mDGResetearSmsAction();
            setTimeout(() => {
                this.timeSuccess = 3000;
                this.smsAction = smsAction;
                this.frameAction = true;
                this.colorMessage = color;
                setTimeout(() => {
                    this.frameAction = false;
                }, 3000);
            }, 150);
        },
        mDGShowError(smsError = ["Corrija todos los errores marcados con rojo"]) {
            this.smsError = smsError;
            this.actionError = true;
            this.dialogError = true;
        },
        mDGProcessAutorization() {
            if (this.withoutAccess.toLogin) {
                localStorage.removeItem("algis");
                this.$router.replace('/login');
            }
            if (this.withoutAccess.withoutAutorization) this.$router.replace('/admin');
        }
    }
}