<template>
    <div
        class="d-flex justify-end mt-1"
    >
        <h1 class="app-private-title mt-1 mr-2" v-if="!isMobile">
            {{title}}
        </h1>
        <div class="c-fill-notice-form">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isMobile: Boolean,
        title: String
    }
}
</script>