<template>
    <div class="o-table-messages animation-opacity">
        <table class="o-table-messages__table">
            <thead>
                <tr>
                    <th width="50">
                        <h1 class="app-private-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Fotografía</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Título</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Descripción</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Estado</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Fecha</h1>
                    </th>
                    <th width="160">
                        <h1 class="app-private-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(notice, index) in listNotices"
                    :key="index"
                >
                    <td>
                        <h1 class="app-private-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <div class="o-table-notices__photo">
                                <v-img
                                    :src="notice.route_photo|imgMiniature"
                                    width="100%"
                                    height="100%"
                                    contain
                                ></v-img>
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{notice.title}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{textNotice(notice.description)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="app-private-subtitle mr-1">
                                {{notice.enabled == 1 ? 'Publicado' : 'No Publicado'}}
                            </h1>
                            <div
                                :class="notice.enabled == 1 ? 'user__success' : 'user__error'"
                            ></div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{`${notice.hour} ${notice.date}`}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <CBtnToolTipTableComp
                                smsToolTip="Detalle Notice"
                                icon="mdi-list-box"
                                @click="$emit('clickdetail', notice)"
                            />
                            <CBtnToolTipTableComp
                                smsToolTip="Editar noticia"
                                icon="mdi-pencil"
                                @click="$emit('clickedit', notice)"
                            />
                            <CBtnToolTipTableComp
                                smsToolTip="Actualizar Fotografía"
                                icon="mdi-message-image"
                                @click="$emit('clickphoto', notice)"
                            />
                            <CBtnToolTipTableComp
                                :smsToolTip="notice.enabled == 1
                                    ? `Inhabilitar noticia` : 'Habilitar noticia'"
                                :icon="notice.enabled == 1
                                    ? 'mdi-eye-off' : 'mdi-eye'"
                                @click="$emit('clickstate', notice)"
                            />
                            <CBtnToolTipTableComp
                                smsToolTip="Eliminar noticia"
                                icon="mdi-delete"
                                @click="$emit('clickdelete', notice)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listNotices.length === 0">
                    <td colspan="7">
                        <h1 class="app-private-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    CBtnToolTipTableComp
} from "../../cells";
import {
    imgMiniature
} from "../../../../filters";
export default {
    filters: {
        imgMiniature
    },
    props: {
        listNotices: Array
    },
    components: {
        CBtnToolTipTableComp
    },
    methods: {
        textNotice(text) {
            let newText = "";
            for (let i = 0; i <= 180; i++) {
                newText += text[i];
                if (i == `${text}`.length) break;
            }
            if (`${text}`.length > 180) newText += "...";
            return newText;
        }
    }
}
</script>