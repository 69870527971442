<template>
    <div>
        <CLoaderOperationComp
            :value="dialogLoaderOperation"
            smsOperation="Realizando Operación"
        />
        <CSmsConfirmationComp
            :value="dialogConfirmation"
            :smsConfirmation="smsConfirmation"
            @clickyes="$emit('clickyes')"
            @clickno="$emit('clickno')"
        />
        <CSmsErrorComp
            :value="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="$emit('clickerror')"
        />
        <CSmsActionComp
            :timeSuccess="timeSuccess"
            :value="frameAction"
            :smsAction="smsAction"
            :colorMessage="colorMessage"
            @clickaccion="$emit('clickaction')"
        />
    </div>
</template>
<script>
import {
    CLoaderOperationComp,
    CSmsConfirmationComp,
    CSmsErrorComp,
    CSmsActionComp
} from "../../cells";
export default {
    components: {
        CLoaderOperationComp,
        CSmsConfirmationComp,
        CSmsErrorComp,
        CSmsActionComp
    },
    props: {
        dialogConfirmation: Boolean,
        smsConfirmation: Array,
        dialogError: Boolean,
        smsError: Array,
        actionError: Boolean,
        smsAction: Array,
        frameAction: Boolean,
        colorMessage: {
            type: String,
            defaul: "success"
        },
        dialogLoaderOperation: Boolean,
        timeSuccess: Number
    }
}
</script>