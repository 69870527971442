import { mapMutations, mapState } from "vuex";
export default {
    computed: {
        ...mapState("userStore", ["existHeader"])
    },
    methods: {
        ...mapMutations("userStore", ["saveHeader", "activateHeader"]),
        mUGuardarHeader() {
            if (JSON.parse(localStorage.getItem("algis"))) {
                if (!this.existHeader) {
                    var apitoken = JSON.parse(localStorage.getItem("algis")).token;
                    var header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + apitoken,
                    }
                    this.saveHeader(header);
                    this.activateHeader();
                }
                return true;
            }
            return false;
        }
    }
}