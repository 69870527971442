import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class User {
    constructor (
        name = null,
        username = null,
        password = null,
        repit_password = null
    ) {
        this.name = name;
        this.username = username;
        this.password = password;
        this.repit_password = repit_password;
    }
    static async getUsers() {
        const response = await axios.get(
            `${apiUrls.USER}/list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addUser(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateUser(parameters) {
        const response = await axios.put(
            `${apiUrls.USER}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeCredentials(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}/change-credentials`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeState(parameters) {
        const response = await axios.patch(
            `${apiUrls.USER}/change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async login(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}/login`,
            parameters
        );
        return response.data;
    }
    static async logout() {
        const response = await axios.post(
            `${apiUrls.USER}/logout`,
            {},
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default User;