<template>
    <v-dialog
        persistent
        :value="value"
        width="400"
    >
        <v-card class="pa-0" color="#FAFAFA">
            <div
                class="red darken-4 d-flex justify-center pa-2">
                <h1 class="app-private-title-dark">ERROR</h1>
            </div>
            <div class="d-flex justify-center pt-2">
                <v-icon x-large class="animation-icon-rotate" color="#d82323">mdi-close-circle</v-icon>
            </div>
            <div class="d-block pa-2">
                <h1
                    class="app-private-subtitle text-center"
                    v-for="(error, index) in smsError" :key="index"
                >
                    {{error}}
                </h1>
            </div>
            <div v-if="actionError" class="text-center pb-2">
                <v-btn
                    small
                    :ripple="false"
                    outlined
                    color="red"
                    @click="$emit('click')"
                >
                    ACEPTAR
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: Boolean,
        smsError: Array,
        actionError: Boolean
    }
}
</script>
