<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/b_6.jpg"
                width="100%"
                height="100%"
            ></v-img>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home__body-container">
                <h1 class="app-public-big-title text-center">Publicaciones</h1>
                <div class="notices-public__publicaciones">
                    <div class="notices-public__article">
                        <div
                            v-if="!cargarnadoPublicacion" class="animation-opacity"
                        >
                            <div
                                v-for="notice in notices"
                                :key="notice.id"
                                class="notices-public__container"
                            >
                                <div class="notices-public__container-photo">
                                    <v-img
                                        :src="notice.route_photo|imgMiniature"
                                        width="100%"
                                        height="100%"
                                    ></v-img>
                                    <div class="notices-public__photo-date">
                                        <h1 class="app-public-title text-center">
                                            {{mDDia(notice.date)}}
                                        </h1>
                                        <h1 class="app-public-subtitle text-center">
                                            {{mDMonth(notice.date)}}
                                        </h1>
                                    </div>
                                </div>
                                <div class="notices-public__container-info">
                                    <div class="notices-public__info-body">
                                        <h1 class="app-public-large-title text-center">
                                            {{notice.title}}
                                        </h1>
                                        <h1 class="app-public-subtitle mt-2" style="text-align: center">
                                            {{textoNoticia(notice.description)}}
                                        </h1>
                                        <div class="d-flex justify-center">
                                            <v-btn
                                                class="mt-2"
                                                color="primary"
                                                text
                                                :ripple="false"
                                                :to="{name: 'publicacion', params: notice}"
                                            >
                                                Seguir Leyendo
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SPaginationComp
                            v-if="!cargarnadoPublicacion && notices.length > 0"
                            class="mt-6"
                            :conditionGo="currentPage < lastPage"
                            :conditionBack="currentPage > 1"
                            :titlePaginate="'Pag. ' + currentPage + ' de ' + lastPage"
                            @clickback="eChangePage(false)"
                            @clickgo="eChangePage(true)"
                        />
                        <v-card
                            class="mt-4 pa-4 text-center animation-opacity"
                            v-if="!cargarnadoPublicacion && notices.length == 0"
                        >SIN PUBLICACIONES</v-card>
                    </div>
                    <div class="notices-public__recommendation">
                        <h1 class="app-public-title text-center">ARTÍCULOS RECIENTES</h1>
                        <v-card
                            height="5"
                            width="100%"
                            class="mt-3 mb-3"
                        ></v-card>
                        <div class="notices-public__recommendation-cards">
                            <div
                                v-for="(recommendation, index) in listRecommendation"
                                :key="recommendation.id"
                            >
                                <v-card
                                    :class="listRecommendation.length == (index -1) ? '' : 'mb-4'"
                                    :to="{name: 'publicacion', params: recommendation}"
                                    class="notices-public__recommendation-card"
                                    elevation="0"
                                    :ripple="false"
                                >
                                    <div class="notices-public__img">
                                        <v-img
                                            :src="recommendation.route_photo|imgMiniature"
                                            width="100%"
                                            height="100%"
                                        ></v-img>
                                    </div>
                                    <div class="ml-2 notices-public__recommendation-text">
                                        <h1 class="app-public-subtitle" style="text-align: left">{{recommendation.title}}</h1>
                                        <h1 class="app-public-subtitle">{{recommendation.date}}</h1>
                                    </div>
                                </v-card>
                                <v-card
                                    height="5"
                                    width="100%"
                                    class="mt-3 mb-3"
                                ></v-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Notice } from "../../models";
import {
    SPaginationComp
} from "../../components/private/species";
import {
    imgMiniature
} from "../../filters";
import {
    dateMixin
} from "../../mixins";
export default {
    filters: {
        imgMiniature
    },
    components: {
        SPaginationComp
    },
    mixins: [
        dateMixin
    ],
    data: () => ({
        notices: [],
        page: 0,
        lastPage: 0,
        currentPage: 0,
        total: 0,
        cargarnadoPublicacion: false,
        listRecommendation: []
    }),
    methods: {
        async getRequest() {
            this.cargarnadoPublicacion = true;
            try {
                const notices = Notice.getNoticesPublic(1);
                const recommendations = Notice.recommendations();
                const responses = await Promise.all([notices, recommendations]);
                this.notices = responses[0].data;
                this.lastPage = responses[0].last_page;
                this.currentPage = responses[0].current_page;
                this.total = responses[0].total;
                this.listRecommendation = responses[1];
                this.cargarnadoPublicacion = false;
            } catch (error) {
                console.log(error);
            }
        },
        async getNotices(page) {
            this.cargarnadoPublicacion = true;
            try {
                const response = await Notice.getNoticesPublic(page);
                this.notices = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.cargarnadoPublicacion = false;
            } catch(error) {
                console.log(error);
            }
        },
        textoNoticia(text) {
            let newText = "";
            if (`${text}`.length <= 175) return text;
            for (let i = 0; i <= 175; i++) {
                newText += text[i];
                if (i == `${text}`.length) break;
            }
            if (`${text}`.length > 200) newText += "...";
            return newText;
        },
        eChangePage(state) {
            if (state) this.currentPage++;
            else this.currentPage--;
            this.getNotices(this.currentPage);
        }
    },
    mounted() {
        this.getRequest();
    }
}
</script>