<template>
    <div class="space-bottom">
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_2.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="home__body space-top">
            <div class="home__body-container">
                <h1 class="app-public-big-title text-center">Nuestros Clientes</h1>
                <h1 class="app-public-subtitle color-secondary mt-2">
                    Estamos agradecidos de ser un negocio esencial, lo que nos permite servirle
                    cuando más lo necesita, con nosotros encontrará el soporte adecuado, para
                    llevar a cabo todos sus proyectos gráficos, esperamos brindarle los productos
                    que necesita para administrar su negocio.
                </h1>
                <h1 class="app-public-subtitle color-secondary mt-2">
                    Las experiencias durante los dos últimos periodos se encuentran:
                </h1>
            </div>
        </div>
        <div class="d-flex justify-center space-top">
            <div class="home__cards d-flex justify-center flex-wrap">
                <v-img
                    class="clients__logo"
                    v-for="(img, index) in listClients"
                    :key="index"
                    :src="img"
                    width="100%"
                    max-width="150"
                    contain
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        listClients: [
            "/imgs/clients/cliente_1.png",
            "/imgs/clients/cliente_16.jpeg",
            "/imgs/clients/cliente_2.png",
            "/imgs/clients/cliente_3.jpg",
            "/imgs/clients/cliente_4.jpg",
            "/imgs/clients/cliente_5.jpg",
            "/imgs/clients/cliente_6.png",
            "/imgs/clients/cliente_7.jpg",
            "/imgs/clients/cliente_8.png",
            "/imgs/clients/cliente_9.png",
            "/imgs/clients/cliente_10.jpg",
            "/imgs/clients/cliente_11.png",
            "/imgs/clients/cliente_13.png",
            "/imgs/clients/cliente_14.png",
            "/imgs/clients/cliente_15.png"
        ]
    })
}
</script>