<template>
    <div>
        <div class="pa-2 d-flex align-center" style="height:48px">
            <h1 class="app-private-title">
                {{`USUARIOS (${total})`}}
            </h1>
            <v-spacer></v-spacer>
            <CBtnToolTipHeaderComp
                icon="mdi-plus"
                class="mr-1"
                smsToolTip="Abrir formulario"
                @click="tab = 1"
            />
            <CBtnToolTipHeaderComp
                icon="mdi-database"
                smsToolTip="Volver atrás"
                @click="eBackList()"
            />
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <CLoaderDataComp
                    v-if="dialogLoaderData"
                />
                <MWithoutDataComp
                    class="ma-2"
                    v-if="!dialogLoaderData && isMobile && listUsers.length == 0"
                />
                <OTableUsersComp
                    v-if="!dialogLoaderData && !isMobile"
                    :listUsers="listUsers"
                    @clickedit="eOpenEditForm"
                    @clickcredentials="eOpenCredentialsForm"
                    @clickstate="eConfirmChangeState"
                />
                <div
                    v-if="!dialogLoaderData && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <OCardUserComp
                        v-for="user in listUsers"
                        :key="user.id"
                        class="ma-2"
                        :user="user"
                        @clickedit="eOpenEditForm(user)"
                        @clickcredentials="eOpenCredentialsForm(user)"
                        @clickstate="eConfirmChangeState(user)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <SSkeletonFormComp
                        :title="numberOperation == 0 ? 'Registrar'
                            : numberOperation == 1
                                ? 'Editar'
                                : 'Cambiar Credenciales'"
                    >
                        <OFormUserComp
                            ref="formusercomp"
                            :dataUser="dataUser"
                            :numberOperation="numberOperation"
                            :isMobile="isMobile"
                            @clickform="eValidateForm"
                        />
                    </SSkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OGrupoGeneralComp
            :dialogConfirmation="dialogConfirmation"
            :smsConfirmation="smsConfirmation"
            :dialogError="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            :smsAction="smsAction"
            :frameAction="frameAction"
            :colorMessage="colorMessage"
            :dialogLoaderOperation="dialogLoaderOperation"
            :timeSuccess="timeSuccess"
            @clickaction="mDGResetearSmsAction()"
            @clickerror="eCloseError()"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MWithoutDataComp
} from "../../components/private/molecules";
import {
    CLoaderDataComp,
    CBtnToolTipHeaderComp
} from "../../components/private/cells";
import { OTableUsersComp } from "../../components/private/organisms/tables";
import { OFormUserComp } from "../../components/private/organisms/forms";
import { OGrupoGeneralComp } from "../../components/private/organisms/others";
import { OCardUserComp } from "../../components/private/organisms/cards";
import {
    SSkeletonFormComp
} from "../../components/private/species";
import {
    User
} from "../../models";
import {
    dateMixin,
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServer,
    userMixin
} from "../../mixins";
export default {
    mixins: [
        dateMixin,
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServer,
        userMixin
    ],
    components: {
        MLineHeaderComp,
        CLoaderDataComp,
        MWithoutDataComp,
        OTableUsersComp,
        SSkeletonFormComp,
        OFormUserComp,
        CBtnToolTipHeaderComp,
        OGrupoGeneralComp,
        OCardUserComp
    },
    data: () => ({
        listUsers: [],
        tab: 0,
        numberOperation: 0,
        dataUser: new User,
        indexUser: -1,
        idUser: 0
    }),
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.smsError = this.mRSSmsError(error);
            this.actionError = this.mRSValueAction(error);
            this.withoutAccess = this.mRSAutorization(error);
            this.dialogError = true;
        },
        eCloseError() {
            this.mDGProcessAutorization();
            this.eCloseConfirm();
            this.dialogError = false;
        },
        eCloseConfirm() {
            if (this.numberOperation == 3) {
                this.indexUser = -1;
                this.idUser = 0;
                this.numberOperation = 0;
            }
            this.dialogConfirmation = false;
        },
        async getUsers() {
            this.dialogLoaderData = true;
            try {
                const response = await User.getUsers();
                this.listUsers = response;
                this.total = response.length;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eBackList() {
            if (this.tab != 0) {
                this.dataUser = new User;
                this.numberOperation = 0;
                this.$refs.formusercomp.resetForm();
                this.tab = 0;
                this.indexUser = -1;
                this.dialogLoaderOperation = false;
            }
        },
        eValidateForm(isValidForm) {
            const time = this.mASTime();
            this.$vuetify.goTo(0, this.mASOpctions(time));
            setTimeout(() => {
                if (!isValidForm) this.mDGShowError();
                else {
                    if (this.numberOperation == 0)
                        this.smsConfirmation = [`¿Está seguro(a) de registrar el usuario de: ${this.dataUser.name}?`];
                    else if (this.numberOperation == 1)
                        this.smsConfirmation = [`¿Está seguro(a) de editar de: ${this.dataUser.name}?`];
                    else this.smsConfirmation = [`¿Está seguro(a) de cambiar las credenciales de: ${this.dataUser.name}?`];
                    this.dialogConfirmation = true;
                }
            }, time);
        },
        async addUser() {
            let md5 = require("md5");
            const parameters = {
                "name": this.dataUser.name,
                "username": this.dataUser.username,
                "password": md5(this.dataUser.password)
            }
            try {
                const response = await User.addUser(parameters);
                this.listUsers.unshift(response);
                this.total += 1;
                this.eBackList();
                this.mDGShowSmsAction(["Operación Exitosa", "Se registró el usuario"]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async editUser() {
            const parameters = {
                "id": this.dataUser.id,
                "name": this.dataUser.name
            }
            try {
                const response = await User.updateUser(parameters);
                Object.assign(this.listUsers[this.indexUser], response);
                this.eBackList();
                this.mDGShowSmsAction(["Operación Exitosa", "Se registró el usuario"]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changeCredentials() {
            let md5 = require("md5");
            const parameters = {
                "id": this.dataUser.id,
                "username": this.dataUser.username,
                "password": md5(this.dataUser.password)
            }
            try {
                const response = await User.changeCredentials(parameters);
                Object.assign(this.listUsers[this.indexUser], response);
                this.eBackList();
                this.mDGShowSmsAction(["Operación Exitosa", "Se cambió las credenciales"]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changeState() {
            try {
                const response = await User.changeState({id: this.idUser});
                const textSms = response == 1 ? 'habilitó' : 'inhbilitó';
                this.listUsers[this.indexUser].enabled = response;
                this.eCloseConfirm();
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", `Se ${textSms} el usuario`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmation = false;
            this.dialogLoaderOperation = true;
            switch(this.numberOperation) {
                case 0:
                    this.addUser();
                    break;
                case 1:
                    this.editUser();
                    break;
                case 2:
                    this.changeCredentials();
                    break;
                case 3:
                    this.changeState();
            }
        },
        eOpenEditForm(user) {
            this.indexUser = this.listUsers.indexOf(user);
            this.numberOperation = 1;
            this.dataUser = Object.assign({}, user);
            const time = this.mASTime();
            this.$vuetify.goTo(0, this.mASOpctions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eOpenCredentialsForm(user) {
            this.indexUser = this.listUsers.indexOf(user);
            this.numberOperation = 2;
            this.dataUser = Object.assign({}, user);
            const time = this.mASTime();
            this.$vuetify.goTo(0, this.mASOpctions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eConfirmChangeState(user) {
            this.numberOperation = 3;
            this.indexUser = this.listUsers.indexOf(user);
            this.idUser = user.id;
            const textState = user.enabled == 0 ? 'habilitar' : 'inhabilitar';
            this.smsConfirmation = [`¿Está seguro(a) de ${textState} a: ${user.name}?`];
            this.dialogConfirmation = true;
        }
    },
    mounted() {
        if (this.mUGuardarHeader()) {
            this.getUsers();
        } else this.$router.replace('/login');
    }
}
</script>