<template>
    <div class="d-flex align-center">
        <h1 class="app-private-title">{{`${title}:`}}</h1>
        <h1 class="app-private-subtitle ml-1">{{subtitle}}</h1>
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        subtitle: String
    }
}
</script>