import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Message {
    constructor (
        name = null,
        affair = null,
        e_mail = null,
        phone = null,
        comment = null,
        info = null
    ) {
        this.name = name;
        this.affair = affair;
        this.e_mail = e_mail;
        this.phone = phone;
        this.comment = comment;
        this.info = info;
    }
    static async getMessages(page, dateFrom, dateTo) {
        const response = await axios.get(
            `${apiUrls.MESSAGE}/list?page=${page}&date_from=${dateFrom}&date_to=${dateTo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addMessage(parameters) {
        const response = await axios.post(
            `${apiUrls.MESSAGE}/add`,
            parameters
        );
        return response.data;
    }
    static async deleteMessage(parameters) {
        const response = await axios.delete(
            `${apiUrls.MESSAGE}/delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Message;