<template>
    <v-dialog
        :value="value"
        persistent
        width="280"
    >
        <v-card>
            <div class="d-flex justify-center pa-3 pb-2">
                <v-img
                    src="/imgs/logos/logo_small.png"
                    width="160"
                    max-width="160"
                    min-width="160"
                ></v-img>
            </div>
            <v-progress-linear
                indeterminate
                height="7"
            ></v-progress-linear>
            <div class="pb-2 pt-2">
                <h1 class="app-private-title text-center">
                    {{smsOperation}}
                </h1>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        smsOperation: String
    }
}
</script>
