<template>
    <div>
        <div class="o-footer d-flex justify-center">
            <div class="home__body-container">
                <div class="o-footer__contianer">
                    <div class="o-footer__first-block">
                        <div class="o-footer__second-block o-footer__second-block--border">
                            <h1 class="app-public-title-dark">PRODUCTOS</h1>
                            <router-link to="/marketing" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Artículos de Marketing</h1>
                            </router-link>
                            <router-link to="/articulos-oficina" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Artículos de Oficina</h1>
                            </router-link>
                            <router-link to="/articulos-promocionales" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Artículos Promocionales</h1>
                            </router-link>
                            <router-link to="/ferias-publicidad" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Ferias y Publicidad</h1>
                            </router-link>
                        </div>
                        <div class="o-footer__second-block o-footer__second-block--border-two">
                            <h1 class="app-public-title-dark">SOBRE NOSOTROS</h1>
                            <router-link to="/ferias-publicidad" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Misión y Visión</h1>
                            </router-link>
                            <router-link to="/ferias-publicidad" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Nuestros Valores</h1>
                            </router-link>
                        </div>
                    </div>
                    <div class="o-footer__first-block">
                        <div class="o-footer__second-block o-footer__second-block--border">
                            <h1 class="app-public-title-dark">SERVICIOS</h1>
                            <router-link to="/ferias-publicidad" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Clientes</h1>
                            </router-link>
                            <router-link to="/ferias-publicidad" class="o-footer__link">
                                <h1 class="app-public-subtitle-dark">Diseño</h1>
                            </router-link>
                        </div>
                        <div class="o-footer__second-block">
                            <h1 class="app-public-title-dark">CONTACTO</h1>
                            <h1 class="app-public-subtitle-dark">
                                {{`Calle: ${setting.address}`}}
                            </h1>
                            <h1 class="app-public-subtitle-dark">
                                {{`Telf: ${setting.phone}`}}
                            </h1>
                            <h1 class="app-public-subtitle-dark">
                                {{`Cel.: ${setting.cell_phone}`}}
                            </h1>
                            <h1
                                v-if="setting.e_mail != null"
                                class="app-public-subtitle-dark"
                            >
                                {{`${setting.e_mail}`}}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center">
            <div class="home__body-container">
                <div class="o-footer__line"></div>
            </div>
        </div>
        <div class="o-footer__network-socials d-flex justify-center align-center pa-4">
            <div>
                <h1 class="app-public-subtitle-dark">Mantente en contacto</h1>
                <h1 class="app-public-title-dark">SIGUENOS EN</h1>
            </div>
            <div class="o-footer__links">
                <v-icon
                    v-if="setting.facebook != null"
                    @click="eOpenNetworkSocial(setting.facebook)"
                    large
                    class="color-dark mr-2"
                >mdi-facebook</v-icon>
                <v-icon
                    v-if="setting.twitter != null"
                    @click="eOpenNetworkSocial(setting.twitter)"
                    large
                    class="color-dark mr-2"
                >mdi-twitter</v-icon>
                <v-icon
                    v-if="setting.instagram != null"
                    @click="eOpenNetworkSocial(setting.instagram)"
                    large
                    class="color-dark"
                >mdi-instagram</v-icon>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        setting: Object
    },
    methods: {
        eOpenNetworkSocial(link) {
            window.open(link);
        }
    }
}
</script>