<template>
    <div>
        <div class="pa-2 d-flex align-center" style="height:48px">
            <h1 class="app-private-title">
                {{`NOTICIAS (${total})`}}
            </h1>
            <v-spacer></v-spacer>
            <CBtnToolTipHeaderComp
                icon="mdi-plus"
                class="mr-1"
                smsToolTip="Abrir formulario"
                @click="tab = 1"
            />
            <CBtnToolTipHeaderComp
                icon="mdi-database"
                smsToolTip="Volver atrás"
                @click="eBackList()"
            />
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <v-card
                        width="200px"
                        elevation="0"
                        class="mr-1"
                    >
                        <MTextFieldComp
                            v-model="dateFrom"
                            label="Fecha inicio"
                            type="date"
                            :hideDetails="true"
                            :clearable="false"
                            @change="eChangeDateFrom"
                        />
                    </v-card>
                    <v-card
                        width="200px"
                        elevation="0"
                        class="ml-1"
                    >
                        <MTextFieldComp
                            v-model="dateTo"
                            label="Fecha final"
                            type="date"
                            :hideDetails="true"
                            @change="eChangeDateTo"
                        />
                    </v-card>
                </div>
                <v-divider></v-divider>
                <CLoaderDataComp
                    v-if="dialogLoaderData"
                />
                <MWithoutDataComp
                    class="ma-2"
                    v-if="!dialogLoaderData && isMobile && listNotices.length == 0"
                />
                <OTableNoticesComp
                    v-if="!dialogLoaderData && !isMobile"
                    :listNotices="listNotices"
                    @clickstate="eConfimChangeState"
                    @clickedit="eOpenFormEdit"
                    @clickdelete="eConfirmDelete"
                    @clickphoto="eOpenFormPhoto"
                    @clickdetail="eOpenDetailNotice"
                />
                <SPaginationComp
                    v-if="!dialogLoaderData && listNotices.length > 0"
                    class="mt-2 mb-2"
                    :isMobile="isMobile"
                    :conditionGo="currentPage < lastPage"
                    :conditionBack="currentPage > 1"
                    :titlePaginate="'Pag. ' + currentPage + ' de ' + lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <SSkeletonFormComp
                        style="width:100% !important"
                        :title="indexNotice == -1 ? 'Registrar' : 'Editar'"
                    >
                        <OFormNoticeComp
                            :dataNotice="dataNotice"
                            :isMobile="isMobile"
                            :indexNotice="indexNotice"
                            ref="formnoticecomp"
                            @clickform="eValidateForm"
                        />
                    </SSkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OFormPhotoComp
            :value="dialogPhoto"
            :notice="notice"
            ref="formphotocomp"
            @clickclose="dialogPhoto = false"
            @clickform="eValidForm"
        />
        <v-dialog
            persistent
            width="1000"
            v-model="dialogDetail"
        >
            <v-card>
                <div class="d-flex align-center pa-2 pl-4 pr-4">
                    <h1 class="app-public-title">Detalle Noticia</h1>
                    <v-spacer></v-spacer>
                    <v-btn
                        :ripple="false"
                        fab
                        color="red"
                        small
                        dark
                        depressed
                        @click="dialogDetail = false"
                    >x</v-btn>
                </div>
                <v-divider></v-divider>
                <div class="pa-4 pt-2">
                    <h1 class="app-private-title">
                        {{notice.title}}
                    </h1>
                    <div class="app-text-space mt-1">
                        <h1 class="app-private-subtitle">
                            {{notice.description}}
                        </h1>
                    </div>
                    <div v-if="notice.link_one != null && notice.link_two">
                        <h1 class="app-private-title">
                            Links
                        </h1>
                        <h1 class="app-private-subtitle">
                            {{notice.link_one}}
                        </h1>
                        <h1 class="app-private-subtitle">
                            {{notice.link_two}}
                        </h1>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <OGrupoGeneralComp
            :dialogConfirmation="dialogConfirmation"
            :smsConfirmation="smsConfirmation"
            :dialogError="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            :smsAction="smsAction"
            :frameAction="frameAction"
            :colorMessage="colorMessage"
            :dialogLoaderOperation="dialogLoaderOperation"
            :timeSuccess="timeSuccess"
            @clickaction="mDGResetearSmsAction()"
            @clickerror="eCloseError()"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MWithoutDataComp,
    MTextFieldComp
} from "../../components/private/molecules";
import {
    CLoaderDataComp,
    CBtnToolTipHeaderComp
} from "../../components/private/cells";
import {
    OGrupoGeneralComp
} from "../../components/private/organisms/others";
import {
    OTableNoticesComp
} from "../../components/private/organisms/tables";
import {
    OFormNoticeComp,
    OFormPhotoComp
} from "../../components/private/organisms/forms";
import {
    SSkeletonFormComp,
    SPaginationComp
} from "../../components/private/species"
import {
    Notice
} from "../../models";
import {
    dateMixin,
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServer,
    userMixin
} from "../../mixins";
import { serialize } from 'object-to-formdata';
export default {
    components: {
        MLineHeaderComp,
        MWithoutDataComp,
        MTextFieldComp,
        CLoaderDataComp,
        OGrupoGeneralComp,
        OTableNoticesComp,
        CBtnToolTipHeaderComp,
        SSkeletonFormComp,
        OFormNoticeComp,
        OFormPhotoComp,
        SPaginationComp
    },
    mixins: [
        dateMixin,
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServer,
        userMixin
    ],
    data: () => ({
        listNotices: [],
        dateFrom: "",
        dateTo: "",
        enabled: 2,
        numberOperation: 0,
        tab: 0,
        dataNotice: new Notice,
        indexNotice: -1,
        idNotice: 0,
        notice: new Notice,
        dialogPhoto: false,
        dataPhoto: {},
        dialogDetail: false
    }),
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.smsError = this.mRSSmsError(error);
            this.actionError = this.mRSValueAction(error);
            this.withoutAccess = this.mRSAutorization(error);
            this.dialogError = true;
        },
        eCloseError() {
            this.mDGProcessAutorization();
            this.eCloseConfirm();
            this.dialogError = false;
        },
        eCloseConfirm() {
            if (this.numberOperation == 4)
                this.dialogPhoto = true;
            else if (this.idNotice != 0) this.resetData();
            this.dialogConfirmation = false;
        },
        eBackList() {
            if (this.tab != 0) {
                this.dataNotice = new Notice;
                this.$refs.formnoticecomp.resetearForm();
                this.numberOperation = 0;
                this.indexNotice = -1;
                this.tab = 0;
            }
        },
        async getNotices(page, dateFrom, dateTo, enabled) {
            this.dialogLoaderData = true;
            try {
                const response = await Notice.getNotices(page, dateFrom, dateTo, enabled);
                this.listNotices = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateFrom(getDateFrom) {
            if (getDateFrom == "" || this.dateTo == "")
                this.mDGShowSmsAction(["Operación Incorrecta", "Seleccione fechas"], "error");
            else {
                if (getDateFrom > this.dateTo) this.dateTo = this.dateFrom;
                this.getNotices(1, this.dateFrom, this.dateTo, this.enabled);
            }
        },
        eChangeDateTo(getDateTo) {
            if (getDateTo == "" || this.dateFrom == "")
                this.mDGShowSmsAction(["Operación Incorrecta", "Seleccione fechas"], "error");
            else {
                if (getDateTo < this.dateFrom) this.dateFrom = this.dateTo;
                this.getNotices(1, this.dateFrom, this.dateTo, this.enabled);
            }
        },
        eValidateForm(isValidForm) {
            if (!isValidForm)
                this.mDGShowSmsAction(["Formulario Erróneo", "Ingrese los datos"], "error");
            else {
                if (this.indexNotice == -1) {
                    if (this.dataNotice.file == null)
                        this.mDGShowSmsAction(["Formulario Erróneo", "Ingrese la fotografía"], "error");
                    else {
                        this.smsConfirmation = [`¿Está seguro(a) de registrar la noticia?`];
                        this.dialogConfirmation = true;
                    }
                } else {
                    this.smsConfirmation = [`¿Está seguro(a) de editar la noticia?`];
                    this.dialogConfirmation = true;
                }
            }
        },
        async addNotice() {
            const formData = serialize(this.dataNotice);
            try {
                const response = await Notice.addNotice(formData);
                this.listNotices.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", "Se registró la noticia"]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async editNotice() {
            try {
                const response = await Notice.editNotice(this.dataNotice);
                Object.assign(this.listNotices[this.indexNotice], response);
                this.eBackList();
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", "Se editó la noticia"]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        resetData() {
            this.idNotice = 0;
            this.indexNotice = -1;
            this.numberOperation = 0;
        },
        async changeState() {
            try {
                const response = await Notice.changeState({id: this.idNotice});
                const textResponse = response == 1 ? "habilitó" : "inhabilitó";
                this.listNotices[this.indexNotice].enabled = response;
                this.resetData();
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", `Se ${textResponse} la noticia`]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async deleteNotice() {
            try {
                await Notice.deleteNotice({id: this.idNotice});
                this.listNotices.splice(this.indexNotice, 1);
                this.resetData();
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", `Se eliminó la noticia`]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async updatePhoto() {
            const parameters = {
                "id": this.notice.id,
                "name_photo": this.dataPhoto.name_photo,
                "file": this.dataPhoto.file
            }
            const formData = serialize(parameters);
            try {
                const response = await Notice.updatePhoto(formData);
                const index = this.listNotices.indexOf(this.notice);
                Object.assign(this.listNotices[index], response);
                this.resetData();
                this.$refs.formphotocomp.resetData();
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", `Se actualizó la fotografía`]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmation = false;
            this.dialogLoaderOperation = true;
            switch (this.numberOperation) {
                case 0:
                    this.addNotice();
                    break;
                case 1:
                    this.editNotice();
                    break;
                case 2:
                    this.changeState();
                    break;
                case 3:
                    this.deleteNotice();
                    break;
                case 4:
                    this.updatePhoto();
            }
        },
        eConfimChangeState(notice) {
            this.numberOperation = 2;
            this.indexNotice = this.listNotices.indexOf(notice);
            this.idNotice = notice.id;
            const textState = notice.enabled == 1 ? "inhabilitar" : "habilitar";
            this.smsConfirmation = [`¿Estás seguro(a) de ${textState} la noticia?`];
            this.dialogConfirmation = true;
        },
        eOpenFormEdit(notice) {
            this.numberOperation = 1;
            this.indexNotice = this.listNotices.indexOf(notice);
            this.dataNotice = Object.assign({}, notice);
            this.tab = 1;
        },
        eConfirmDelete(notice) {
            this.numberOperation = 3;
            this.indexNotice = this.listNotices.indexOf(notice);
            this.idNotice = notice.id;
            this.smsConfirmation = [`¿Está seguro(a) de eliminar la noticia?`];
            this.dialogConfirmation = true;
        },
        eOpenFormPhoto(notice) {
            this.numberOperation = 4;
            this.notice = notice;
            this.dialogPhoto = true;
        },
        eValidForm(parameters) {
            if (parameters.file == null)
                this.mDGShowSmsAction(["Operación Cancelada", `Ingrese una fotografía`], "error");
            else {
                this.dialogPhoto = false;
                this.dataPhoto = parameters;
                this.smsConfirmation = [`¿Está seguro(a) de actualizar la fotografía?`];
                this.dialogConfirmation = true;
            }
        },
        eChangePage(state) {
            if (state) this.currentPage++;
            else this.currentPage--;
            this.getNotices(this.currentPage, this.dateFrom, this.dateTo, 2);
        },
        eOpenDetailNotice(notice) {
            this.notice = notice;
            this.dialogDetail = true;
        }
    },
    mounted() {
        if (this.mUGuardarHeader()) {
            this.dateFrom = this.dateTo = this.mDSystem(false);
            this.getNotices(1, "", "", 2);
        } else this.$router.replace('/login');
    }
}
</script>