export default {
    data: () => ({
        smsEmpty: "Es requerido",
        smsWithoutSpace: "No debe tener espacios",
        smsNoInt: "Debe ser un número entero",
        smsNoDecimal: "Debe ser un número",
        smsNoZero: "No debe ser cero"
    }),
    methods: {
        mRSmsExecedCounter(digites) {
            return `No puede exceder los ${digites} dígitos`;
        },
        mRIsEmpty(input) {
            if (input === null || input === '') return true;
            var filterSpaces = /\s/g;
            var myArraySpaces = (input+'').match(filterSpaces);
            if (myArraySpaces === null) return false;
            return input.length === myArraySpaces.length;
        },
        mRWithSpaces(input) {
            var spaces = (`${input}`).match(/\s+/g);
            return !(spaces === null);
        },
        mRIsInt(input) {
            if (isNaN(input)) return false;
            var number = parseFloat(input);
            return number % 1 === 0;
        },
        mRIsDecimal(input) {
            if (isNaN(input)) return false;
            return !(input < 0);
        },
        mRExcededLimit(input, limit) {
            return parseFloat(input) > parseFloat(limit);
        },
        mRIsNotZero(input) {
            return parseInt(input) !== 0;
        },
        mREquals(inputOne, inputTwo) {
            return inputOne === inputTwo;
        }
    }
}