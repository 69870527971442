<template>
    <div class="c-info-home" v-intersect="onIntersect">
        <div class="home__body space-top">
            <div class="home__body-container">
                <h1 class="app-public-subtitle color-secondary">
                    ALGIS es una empresa que se dedica a satisfacer las
                    necesidades integrales en material
                    de impresión, brindando soluciones
                    gráficas de gran impacto visual. Con nosotros dispone
                    del asesoramiento profesional necesario para cubrir
                    todas sus necesidades gráficas, somos un equipo al
                    que nos apasiona nuestro trabajo, lo que nos hace 
                    involucrar en él, para conseguir los mejores resultados 
                    a sus necesidades de impresión y diseño, tenemos un amplio
                    conocimiento de las artes gráficas, te ofrecemos calidad,
                    rapidez, excelente servicio y buen precio. Estamos
                    comprometidos con nuestros lineamientos, clientes y
                    medio ambiente.
                </h1>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        activateAnimation: false,
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        animation() {
            const nodo = document.getElementsByClassName("c-info-home");
            //nodo[0].style.transition = "all 2s";
            nodo[0].style.marginTop = "0px";
            nodo[0].style.opacity = 1;
        },
        onIntersect (entries, observer) {
            if (!this.activateAnimation 
                && entries[0].isIntersecting
                && !this.isMobile) {
                this.animation();
                this.isIntersecting = entries[0].isIntersecting;
            }
        },
        cancelAnimation() {
            this.isMobile = true;
            const nodo = document.getElementsByClassName("c-info-home");
            nodo[0].style.marginTop = "0px";
            nodo[0].style.opacity = 1;
        }
    },
    mounted() {
        if (window.innerWidth <= 650) this.cancelAnimation(); 
    }
}
</script>