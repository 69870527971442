<template>
    <v-card class="p-background-secondary animation-opacity">
        <h1 class="app-private-title-dark text-center">{{titulo}}</h1>
    </v-card>
</template>
<script>
export default {
    props: {
        titulo: {
            type: String,
            default: "SIN DATOS"
        }
    }
}
</script>