<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/disenio/disenio_1.jpg"
                width="100%"
                height="100%"
                position="left"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="home__body space-top">
            <div class="home__body-container">
                <h1 class="app-public-big-title text-center">Nuestros Servicios</h1>
                <h1 class="app-public-subtitle color-secondary mt-2">
                    Nuestros servicios abarcan todo el proceso, desde el diseño
                    o la maquetación pasando por la impresión, el acabado y la
                    distribución. Podemos realizar desde unas sencillas tarjetas
                    hasta el catálogo más complejo.
                </h1>
                <h1 class="app-public-subtitle color-secondary mt-2">
                    La producción habitual comprende impresos desde membretes,
                    volantes, catálogos, revistas, folletos, libros y cualquier
                    tipo de impresos comerciales, que permitan asegurar que su
                    empresa sea conocida por su cliente estar presentes con
                    trabajos de calidad en un mundo que cada día cuida y valora
                    más la imagen y la presentación.
                </h1>
            </div>
        </div>
        <div class="d-flex justify-center flex-wrap space-top space-bottom">
            <div class="services__photograpy">
                <v-img
                    src="/imgs/disenio/tarjetones1.jpg"
                    width="100%"
                ></v-img>
            </div>
            <div class="services__photograpy">
                <v-img
                    src="/imgs/disenio/tarjeta_3.png"
                    width="100%"
                ></v-img>
            </div>
            <div class="services__photograpy">
                <v-img
                    src="/imgs/disenio/tarjetones2.jpg"
                    width="100%"
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OFillInfoComp
} from "../../components/public/organisms";
export default {
    components: {
        OFillInfoComp
    },
    data: () => ({
        listInfo: [
            {
                img: "/imgs/disenio/foto.png",
                title: "Nuestros Servicios",
                listText: [
                    `Nuestros servicios abarcan todo el proceso, desde el diseño
                    o la maquetación pasando por la impresión, el acabado y la
                    distribución. Podemos realizar desde unas sencillas tarjetas
                    hasta el catálogo más complejo.`,
                    `La producción habitual comprende impresos desde membretes,
                    volantes, catálogos, revistas, folletos, libros y cualquier
                    tipo de impresos comerciales, que permitan asegurar que su
                    empresa sea conocida por su cliente estar presentes con
                    trabajos de calidad en un mundo que cada día cuida y valora
                    más la imagen y la presentación.`
                ]
            }
        ]
    })
}
</script>