export default {
    methods: {
        mRSValueAction(error) {
            if (error.response !==  undefined ) {
                switch (error.response.status) {
                    case 400: return true;
                    case 500: return false;
                    case 402: return true;
                    case 401: return true;
                    case 403: return true;
                    default: return false;
                }
            }
            return false;
        },
        mRSSmsError(error) {
            if (error.response !==  undefined ) {
                switch (error.response.status) {
                    case 400: return error.response.data;
                    case 500: return [
                        `Error en el proceso de almacenado en la B.D.`,
                        `Revise su concexión de internet y refresque la página (F5).`
                    ];
                    case 401: return [
                        `OPERACIONES CANCELADAS`,
                        `El token de seguridad fue anulado, presiones en aceptar para acceder
                        nuevamente con sus credenciales`
                    ];
                    case 402: return [
                        `USUARIO INHABILITADO`,
                        `Su cuenta fue cancelada, si se cometió un error comuniquese con
                            el administrador porfavor`
                    ];
                    case 403: return [
                        `ACCESO DENEGADO`,
                        `Usted no tiene permiso para realizar la operación solicitada,
                            comuniquese con su superior para más información.`
                    ];
                    default: return [
                        `Error en el servidor`,
                        `Revise su conexión de internet y refresque la página (F5)`
                    ];
                }
            }
            return [`Error de conexión de internet`,
                `Revise su concexión de internet y refresque la página (F5)`
            ];
        },
        mRSAutorization(error) {
            var sinAutorization = false;
            var toLogin = false;
            if (error.response !==  undefined ) {
                if (error.response.status === 401
                    || error.response.status === 402
                    || error.response.status === 403) sinAutorization = true;
                if (error.response.status === 401 || error.response.status === 402) toLogin = true;
            }
            return {
                'toLogin': toLogin,
                'sinAutorization': sinAutorization
            }
        }
    }
}