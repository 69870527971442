export default {
    methods: {
        mASTime() {
            const positionYScroll = window.scrollY;
            if (positionYScroll === 0) return 0;
            if (positionYScroll <= 300) return 200;
            if (positionYScroll <= 600) return 400;
            if (positionYScroll <= 950) return 700;
            if (positionYScroll <= 1300) return 1000;
            if (positionYScroll <= 1650) return 1300;
            return 1600;
        },
        mASOpctions(duration) {
            return {
                duration: duration,
                easing: "easeInOutCubic"
            }
        },
        mASGetTopNode() {
            const node = document.getElementById("position-scroll");
            return node.offsetTop;
        }
    }
}