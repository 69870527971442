<template>
    <div class="pa-2 pt-6">
        <h1 class="app-private-title text-center">BIENVENIDO AL PANEL DE CONTROL</h1>
        <h1 class="app-private-subtitle text-center mt-3">
            Utilice el panel con responsabilidad.
        </h1>
        <h1 class="app-private-subtitle text-center">
            Cierre Sesión después de utilizar el panel.
        </h1>
        <h1 class="app-private-subtitle text-center">
            Revise constantemente la opción de los mensajes.
        </h1>
        <h1 class="app-private-subtitle text-center">
            Publique noticias y comparta en facebook continuamente.
        </h1>
    </div>
</template>
<script>
export default {
    mounted() {
        if (!JSON.parse(localStorage.getItem('algis'))) {
            this.$router.replace('/login');
        }
    }
}
</script>