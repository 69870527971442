<template>
    <div>
        <v-tooltip bottom content-class="p-color-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :width="size"
                    :max-width="size"
                    :min-width="size"
                    :height="size"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    class="p-background-primary"
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <v-icon class="p-color-dark">{{icon}}</v-icon>
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <v-icon class="p-color-dark">mdi-hand-pointing-right</v-icon>
                <h1 class="app-private-title-dark ml-1">{{smsToolTip}}</h1>
            </div>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        icon: String,
        smsToolTip: String
    },
    data: () => ({
        size: 32
    })
}
</script>
