<template>
    <div>
        <div class="pa-2 d-flex align-center" style="height:48px">
            <h1 class="app-private-title">
                {{`MENSAJES (${total})`}}
            </h1>
        </div>
        <MLineHeaderComp />
        <div class="d-flex justify-center pa-2">
            <v-card
                width="200px"
                elevation="0"
                class="mr-1"
            >
                <MTextFieldComp
                    v-model="dateFrom"
                    label="Fecha inicio"
                    type="date"
                    :hideDetails="true"
                    :clearable="false"
                    @change="eChangeDateFrom"
                />
            </v-card>
            <v-card
                width="200px"
                elevation="0"
                class="ml-1"
            >
                <MTextFieldComp
                    v-model="dateTo"
                    label="Fecha final"
                    type="date"
                    :hideDetails="true"
                    @change="eChangeDateTo"
                />
            </v-card>
        </div>
        <v-divider></v-divider>
        <CLoaderDataComp
            v-if="dialogLoaderData"
        />
        <MWithoutDataComp
            class="ma-2"
            v-if="!dialogLoaderData && isMobile && listMessages.length == 0"
        />
        <OTableMessagesComp
            v-if="!dialogLoaderData && !isMobile"
            :listMessages="listMessages"
            @clickdelete="eConfirmDeleteMessage"
        />
        <SPaginationComp
            v-if="!dialogLoaderData && listNotices.length > 0"
            class="mt-2 mb-2"
            :isMobile="isMobile"
            :conditionGo="currentPage < lastPage"
            :conditionBack="currentPage > 1"
            :titlePaginate="'Pag. ' + currentPage + ' de ' + lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
        />
        <OGrupoGeneralComp
            :dialogConfirmation="dialogConfirmation"
            :smsConfirmation="smsConfirmation"
            :dialogError="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            :smsAction="smsAction"
            :frameAction="frameAction"
            :colorMessage="colorMessage"
            :dialogLoaderOperation="dialogLoaderOperation"
            :timeSuccess="timeSuccess"
            @clickaction="mDGResetearSmsAction()"
            @clickerror="eCloseError()"
            @clickyes="eDeleteMessage()"
            @clickno="dialogConfirmation = false"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MWithoutDataComp,
    MTextFieldComp
} from "../../components/private/molecules";
import {
    CBtnToolTipHeaderComp,
    CLoaderDataComp
} from "../../components/private/cells";
import {
    OTableMessagesComp
} from "../../components/private/organisms/tables";
import {
    OGrupoGeneralComp
} from "../../components/private/organisms/others";
import {
    SPaginationComp
} from "../../components/private/species";
import {
    Message
} from "../../models";
import {
    dateMixin,
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServer,
    userMixin
} from "../../mixins";
export default {
    components: {
        MLineHeaderComp,
        CBtnToolTipHeaderComp,
        CLoaderDataComp,
        MWithoutDataComp,
        OTableMessagesComp,
        OGrupoGeneralComp,
        MTextFieldComp,
        SPaginationComp
    },
    mixins: [
        dateMixin,
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServer,
        userMixin
    ],
    data: () => ({
        listMessages: [],
        indexMessage: -1,
        idMessage: 0,
        dateFrom: "",
        dateTo: ""
    }),
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.smsError = this.mRSSmsError(error);
            this.actionError = this.mRSValueAction(error);
            this.withoutAccess = this.mRSAutorization(error);
            this.dialogError = true;
        },
        eCloseError() {
            this.dialogError = false;
        },
        async getMessages(page, dateFrom, dateTo) {
            this.dialogLoaderData = true;
            try {
                const response = await Message.getMessages(page, dateFrom, dateTo);
                this.listMessages = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eConfirmDeleteMessage(message) {
            this.indexMessage = this.listMessages.indexOf(message);
            this.idMessage = message.id;
            this.smsConfirmation = [`¿Está seguro(a) de eliminar el mensaje?`];
            const time = this.mASTime();
            this.$vuetify.goTo(0, this.mASOpctions(time));
            setTimeout(() => {
                this.dialogConfirmation = true;
            }, time);
        },
        async eDeleteMessage() {
            this.dialogConfirmation = false;
            try {
                await Message.deleteMessage({id: this.idMessage});
                this.total -= 1;
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", "Se eliminó el mensaje"]);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateFrom(getDateFrom) {
            if (getDateFrom == "" || this.dateTo == "")
                this.mDGShowSmsAction(["Operación Incorrecta", "Seleccione fechas"], "error");
            else {
                if (getDateFrom > this.dateTo) this.dateTo = this.dateFrom;
                this.getMessages(1, this.dateFrom, this.dateTo);
            }
        },
        eChangeDateTo(getDateTo) {
            if (getDateTo == "" || this.dateFrom == "")
                this.mDGShowSmsAction(["Operación Incorrecta", "Seleccione fechas"], "error");
            else {
                if (getDateTo < this.dateFrom) this.dateFrom = this.dateTo;
                this.getMessages(1, this.dateFrom, this.dateTo);
            }
        },
        eChangePage(state) {
            if (state) this.currentPage++;
            else this.currentPage--;
            this.getMessages(this.currentPage, this.dateFrom, this.dateTo);
        }
    },
    mounted() {
        this.dateFrom = this.dateTo = this.mDSystem(false);
        this.getMessages(1, "", "");
    }
}
</script>