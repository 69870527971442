<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_10.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home__body-container">
                <div>
                    <OFillInfoComp
                        v-for="(info, index) in listInfo"
                        :key="index"
                        :info="info"
                        class="space-bottom"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OFillInfoComp
} from "../../components/public/organisms";
export default {
    components: {
        OFillInfoComp
    },
    data: () => ({
        listInfo: [
            {
                img: "/imgs/oficina/block_notas.png",
                title: "Block de Notas",
                listText: [
                    `Las libretas y blocs de notas personalizados son una manera
                    única de construir tu marca corporativa sin tener que realizar
                    una gran inversión. Los bloc de notas personalizados son una
                    excelente herramienta de presentación y a menudo son el primer
                    contacto entre una empresa, y los potenciales clientes, si
                    estás buscando un regalo publicitario para tus clientes,
                    los blocs de notas personalizados son una magnífica elección.`
                ]
            }, {
                img: "/imgs/oficina/membrete.png",
                title: "Hojas Membretadas",
                listText: [
                    `La hoja membretada aporta elegancia y seriedad al negocio y, por
                    ello, muchos sectores empresariales apuestan por él.`,
                    `El membrete ayuda a agregar autoridad a la marca de la
                    empresa y hace que parezca más calificada, a menudo
                    los posibles clientes reconocen una marca por su logotipo
                    colocado en los membretes.`
                ]
            }, {
                img: "/imgs/oficina/planificador.png",
                title: "Planificador",
                listText: [
                    `Un calendario planificador es una tarea tan sencilla como
                    recomendable para poder organizar mejor el trabajo a realizar,
                    permite escribir en la hora señalada e ir añadiendo nuevas citas
                    posteriormente. La agenda exige buscar el día concreto para
                    consultar qué hay que hacer. El planificador permite, de un
                    simple vistazo, conocer todas las tareas de la semana lo que
                    permite modificar el ritmo de trabajo según convenga.`
                ]
            }, {
                img: "/imgs/oficina/recibos.png",
                title: "Recibos",
                listText: [
                    `Los recibos son una poderosa herramienta que las empresas
                    pueden utilizar para promocionar su marca, sus productos y
                    sus servicios. El diseño del logotipo, el eslogan o el sitio
                    web de su empresa en los recibos recuerda su marca a los
                    clientes cada vez que los miran, su mensaje sea claro, conciso
                    y fácil de leer. Es una poderosa herramienta que puede ayudar
                    a las empresas a mejorar sus esfuerzos a impulsar el
                    crecimiento.`
                ]
            }, {
                img: "/imgs/oficina/calendarios.png",
                title: "Calendarios",
                listText: [
                    `Los calendarios de escritorio son una herramienta de uso diario,
                    la impresión es la forma más económica de reforzar tu imagen de marca.`,
                    `Los calendarios se convierten en herramientas de planificación,
                    organización y control del tiempo y las actividades en la oficina.`
                ]
            }
        ]
    })
}
</script>