var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formuser",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.numberOperation != 2)?_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Nombre:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Nombre' : '',"counter":30,"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 30 || _vm.mRSmsExecedCounter(30)
            ]},model:{value:(_vm.dataUser.name),callback:function ($$v) {_vm.$set(_vm.dataUser, "name", $$v)},expression:"dataUser.name"}})],1):_vm._e(),(_vm.numberOperation == 0 || _vm.numberOperation == 2)?_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Usuario:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Usuario' : '',"counter":30,"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 30 || _vm.mRSmsExecedCounter(30)
            ]},model:{value:(_vm.dataUser.username),callback:function ($$v) {_vm.$set(_vm.dataUser, "username", $$v)},expression:"dataUser.username"}})],1):_vm._e(),(_vm.numberOperation == 0 || _vm.numberOperation == 2)?_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Contraseña:"}},[_c('MTextFieldComp',{attrs:{"type":_vm.showPassword ? 'text' : 'password',"label":_vm.isMobile ? 'Contraseña' : '',"counter":20,"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRWithSpaces(value) || _vm.smsWithoutSpace,
                value => (`${value}`).length <= 20 || _vm.mRSmsExecedCounter(20)
            ]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataUser.password),callback:function ($$v) {_vm.$set(_vm.dataUser, "password", $$v)},expression:"dataUser.password"}})],1):_vm._e(),(_vm.numberOperation == 0 || _vm.numberOperation == 2)?_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Repita Contraseña:"}},[_c('MTextFieldComp',{attrs:{"type":_vm.showPassword ? 'text' : 'password',"label":_vm.isMobile ? 'Repita Contraseña' : '',"counter":20,"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRWithSpaces(value) || _vm.smsWithoutSpace,
                value => _vm.mREquals(value, _vm.dataUser.password) || 'Las contraseñas no coinciden',
                value => (`${value}`).length <= 20 || _vm.mRSmsExecedCounter(20)
            ]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataUser.repit_password),callback:function ($$v) {_vm.$set(_vm.dataUser, "repit_password", $$v)},expression:"dataUser.repit_password"}})],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('MBtnNormalComp',{attrs:{"title":"Guardar"},on:{"click":function($event){return _vm.eValidateForm()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }