<template>
    <div class="publication">
        <div
            v-if="notice.id != undefined"
            class="d-flex justify-center space-top space-bottom"
        >
            <div class="publication__body">
                <div class="publication__article">
                    <h1 class="app-public-big-title text-center">{{notice.title}}</h1>
                    <div class="publication__banner mt-4">
                        <v-img
                            :src="notice.route_photo|imgNormal"
                            width="100%"
                            height="100%"
                        ></v-img>
                    </div>
                    <div class="publication__article-info">
                        <h1 class="app-public-title mb-1 mt-6">
                            {{`Fecha Publicación: ${notice.date}`}}
                        </h1>
                        <div style="white-space: break-spaces;" class="mt-4">
                            <h1 class="app-public-subtitle">
                                {{notice.description}}
                            </h1>
                        </div>
                        <v-btn
                            class="mt-3 mb-1"
                            :ripple="false"
                            color="#056BE1"
                            dark
                            @click="eShareFacebook"
                        >
                            Compartir Facebook
                        </v-btn>
                        <div
                            v-if="notice.link_one != null || notice.link_two != null"
                            style="overflow:hidden"
                        >
                            <h1 class="app-public-title mb-1 mt-2" >
                                Links
                            </h1>
                            <h1
                                v-if="notice.link_one != null"
                                class="app-public-subtitle notices-public__link"
                                @click="eOpenLink(notice.link_one)"
                            >
                                {{notice.link_one}}
                            </h1>
                            <h1
                                v-if="notice.link_two != null"
                                class="app-public-subtitle notices-public__link"
                                @click="eOpenLink(notice.link_two)"
                            >
                                {{notice.link_two}}
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="notices-public__recommendation">
                    <div class="publication__recommendation-space">
                        <h1 class="app-public-title text-center">ARTÍCULOS RECIENTES</h1>
                        <v-card
                            height="5"
                            width="100%"
                            class="mt-3 mb-3"
                        ></v-card>
                        <div class="notices-public__recommendation-cards">
                            <div
                                v-for="(recommendation, index) in listRecommendation"
                                :key="recommendation.id"
                            >
                                <v-card
                                    :class="listRecommendation.length == (index -1) ? '' : 'mb-4'"
                                    :to="{name: 'publicacion', params: recommendation}"
                                    class="notices-public__recommendation-card"
                                    elevation="0"
                                    :ripple="false"
                                >
                                    <div class="notices-public__img">
                                        <v-img
                                            :src="recommendation.route_photo|imgMiniature"
                                            width="100%"
                                            height="100%"
                                        ></v-img>
                                    </div>
                                    <div class="ml-2 notices-public__recommendation-text">
                                        <h1 class="app-public-subtitle">{{recommendation.title}}</h1>
                                        <h1 class="app-public-subtitle">{{recommendation.date}}</h1>
                                    </div>
                                </v-card>
                                <v-card
                                    height="5"
                                    width="100%"
                                    class="mt-3 mb-3"
                                ></v-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Notice } from '../../models';
import {
    imgNormal,
    imgMiniature
} from "../../filters";
export default {
    filters: { imgNormal, imgMiniature },
    data: () => ({
        dialogOperation: false,
        notice: {},
        listRecommendation: [],
        isInitialWidth: true,
        initialWidth: window.innerWidth,
        mobile: false
    }),
    watch: { 
        '$route.params.id': {
            handler: function(id) {
                this.dialogOperation = true
                this.notice = {}
                this.listRecommendation = []
                this.isInitialWidth = true
                setTimeout(() => {
                    this.getRequest(id, 4)
                }, 150)
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ac_getDimensionsImg: function() {
            if (this.isInitialWidth) {
                if (window.innerWidth <= 1180) {
                    const newHeight = parseInt((window.innerWidth * this.notice.height) / this.notice.width)
                    return `width:100%; height:${newHeight}px;`
                } else {
                    const newHeight = parseInt((1180 * this.notice.height) / this.notice.width)
                    return `width:100%; height:${newHeight}px;`
                }
            } else return 'width:100%; height:auto;'
        }
    },
    methods: {
        async getRequest(id, quantity) {
            try {
                const response = await Notice.getNotice(id, quantity);
                this.notice = response.notice;
                this.listRecommendation = response.recommendations;
            } catch (error) {
                console.log(error);
            }
        },
        eOpenLink(link) {
            window.open(link);
        },
        eShareFacebook() {
            window.open(`https://graficasalgis.com.bo/api/notice/send-open-graps?id=${this.notice.id}`, '', "width=600, height=600")
        },
        eSizeLine() {
            if (window.innerWidth <= 700) return "100%";
            return "85%";
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.$route.params.id === undefined) this.$router.replace({name: 'publicaciones'});
    }
}
</script>