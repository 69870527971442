<template>
    <div class="o-fill-info">
        <div class="o-fill-info__img">
            <v-img
                :src="info.img"
                width="100%"
                height="100%"
                contain
            ></v-img>
        </div>
        <div class="o-fill-info__text">
            <div class="d-flex align-center">
                <h1 class="app-public-big-title">{{info.title}}</h1>
            </div>
            <div
                v-for="(text, index) in info.listText"
                :key="index"
            >
                <h1 class="app-public-subtitle mt-2">{{text}}</h1>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Object
    }
}
</script>