<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_7.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="home__body space-top space-bottom">
            <div class="home__body-container mission-vision__body">
                <div class="mission-vision__img">
                    <v-img
                        src="/imgs/mission_vision/mission.png"
                        contain
                    ></v-img>
                </div>
                <div>
                    <div>
                        <h1 class="app-public-big-title">Misión</h1>
                    </div>
                    <h1 class="app-public-subtitle color-secondary mt-1">
                        En imprenta ALGIS nuestra misión ser una organización integral de artes gráficas, con la finalidad de garantizar una eficaz respuesta a las necesidades de sus clientes, que satisfaga los estándares de calidad, rapidez y eficacia, para llevar a cabo todos sus proyectos, apoyándonos en un equipo cualificado que le garantizará un resultado final asesoramiento, diseño, impresión, control de calidad y distribución.
                    </h1>
                    <div class="space-top">
                        <h1 class="app-public-big-title">Visión</h1>
                    </div>
                    <h1 class="app-public-subtitle color-secondary mt-1">
                        Imprenta ALGIS con experiencia en el sector de las artes gráficas hemos adquirido una referencia como proveedores de servicios gráficos en nuestra comunidad, en cuanto a calidad, fiabilidad, eficacia y rapidez. Estamos comprometidos con nuestros lineamientos, clientes y medio ambiente.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>