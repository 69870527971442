<template>
    <div class="o-card-home" v-intersect="onIntersect">
        <h1 class="app-first-letter app-public-title text-center">
            {{product.title}}
        </h1>
        <div class="o-card-home__image mt-2">
            <div class="o-card-home__image-src">
                <v-img
                    :src="product.image"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        product: Object,
        index: Number
    },
    data: () => ({
        activateAnimation: false,
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        animation() {
            const nodo = document.getElementsByClassName("o-card-home");
            nodo[this.index].style.marginTop = "0px";
            nodo[this.index].style.opacity = 1;
        },
        onIntersect (entries, observer) {
            if (!this.activateAnimation 
                && entries[0].isIntersecting
                && !this.isMobile) {
                this.animation();
                this.isIntersecting = entries[0].isIntersecting;
            }
        },
        cancelAnimation() {
            this.isMobile = true;
            const nodo = document.getElementsByClassName("o-card-home");
            nodo[this.index].style.marginTop = "0px";
            nodo[this.index].style.opacity = 1;
        }
    },
    mounted() {
        if (window.innerWidth <= 650) this.cancelAnimation(); 
    }
}
</script>