<template>
    <v-dialog
        persistent
        width="400"
        :value="value"
    >
        <v-card>
            <div class="d-flex align-center pa-2">
                <h1 class="app-private-title">Actualizar Fotografía</h1>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    color="red"
                    @click="eCloseForm"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="pa-2">
                <CFillInfoComp
                    title="Titulo"
                    :subtitle="notice.title == null ? '' : notice.title"
                />
                <div class="d-flex justify-center mb-3 mt-3">
                    <CPhotoComp
                        ref="photocomp"
                        referenceImg="fotografia"
                        title="FOTOGRAFÍA"
                        subtitle="Imágen"
                        :withImgDB="false"
                        @changePhoto="ePhotoSelected"
                    />
                </div>
                <div class="text-center mb-1">
                    <MBtnNormalComp
                        title="Guardar"
                        @click="eValidateForm()"
                    />
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MBtnNormalComp
} from "../../molecules";
import {
    CFillInfoComp,
    CPhotoComp
} from "../../cells";
export default {
    components: {
        CFillInfoComp,
        CPhotoComp,
        MBtnNormalComp
    },
    props: {
        value: Boolean,
        notice: Object
    },
    data: () => ({
        name_photo: "",
        file: null
    }),
    methods: {
        ePhotoSelected(parameters) {
            if (parameters.state) {
                const name = new Date().getTime();
                this.name_photo = name;
                this.file = parameters.file;
            } else {
                this.name_photo = "";
                this.file = null;
            }
        },
        eValidateForm() {
            const parameters = {
                "name_photo": this.name_photo,
                "file": this.file
            }
            this.$emit('clickform', parameters);
        },
        eCloseForm() {
            this.name_photo = "";
            this.file = null;
            this.$emit('clickclose');
        },
        resetData() {
            this.name_photo = "";
            this.file = null;
        }
    }
}
</script>