<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formnotice"
    >
        <div
            v-if="indexNotice == -1"
            class="d-flex justify-center mb-6"
        >
            <CPhotoComp
                ref="photocomp"
                referenceImg="fotografia"
                title="FOTOGRAFÍA"
                subtitle="Imágen"
                :withImgDB="false"
                @changePhoto="ePhotoSelected"
            />
        </div>
        <CFillNoticeFormComp
            :isMobile="isMobile"
            title="Título:"
        >
            <MTextFieldComp
                v-model="dataNotice.title"
                :label="isMobile ? 'Título' : ''"
                :counter="100"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 100 || mRSmsExecedCounter(100)
                ]"
            />
        </CFillNoticeFormComp>
        <CFillNoticeFormComp
            :isMobile="isMobile"
            title="Descripción:"
        >
            <MTextareaComp
                v-model="dataNotice.description"
                :label="isMobile ? 'Descripción' : ''"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty
                ]"
            />
        </CFillNoticeFormComp>
        <CFillNoticeFormComp
            :isMobile="isMobile"
            title="Link Uno:"
        >
            <MTextFieldComp
                v-model="dataNotice.link_one"
                :label="isMobile ? 'Link Uno' : ''"
                :counter="200"
                :rules="[
                    value => (`${value}`).length <= 200 || mRSmsExecedCounter(200)
                ]"
            />
        </CFillNoticeFormComp>
        <CFillNoticeFormComp
            :isMobile="isMobile"
            title="Link Dos:"
        >
            <MTextFieldComp
                v-model="dataNotice.link_two"
                :label="isMobile ? 'Link Dos:' : ''"
                :counter="200"
                :rules="[
                    value => (`${value}`).length <= 200 || mRSmsExecedCounter(200)
                ]"
            />
        </CFillNoticeFormComp>
        <div class="text-center">
            <MBtnNormalComp
                title="Guardar"
                @click="eValidateForm()"
            />
        </div>
    </v-form>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp,
    MTextareaComp
} from "../../molecules";
import {
    CFillNoticeFormComp,
    CPhotoComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        CFillNoticeFormComp,
        MBtnNormalComp,
        MTextareaComp,
        CPhotoComp
    },
    props: {
        isMobile: Boolean,
        dataNotice: Object,
        indexNotice: Number
    },
    data: () => ({
        valid: true,

    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formnotice.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetearForm() {
            this.$refs.formnotice.resetValidation();
            if (this.indexNotice == -1)
                this.$refs.photocomp.resetData();
        },
        ePhotoSelected(parameters) {
            if (parameters.state) {
                const name = new Date().getTime();
                this.dataNotice.name_photo = name;
                this.dataNotice.file = parameters.file;
            } else {
                this.dataNotice.name_photo = "";
                this.dataNotice.file = null;
            }
        }
    }
}
</script>