import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { scrollRoute } from '../mixins';

import {
  Home,
  MissionVision,
  Marketing,
  NuestrosValores,
  Clientes,
  Contact,
  ArticulosOficina,
  ArticulosPromocionales,
  FeriasPublicidad,
  Servicios,
  NoticesPublic,
  Publication
} from "../views/public";

import {
    Messages,
    HomeAdmin,
    Settings,
    Users,
    Notices
} from "../views/private";

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: () => import("../views/public/AppPublic"),
            children: [
                {
                    path: "/",
                    name: "home",
                    component: Home
                }, {
                    path: "/mission-vision",
                    name: "mission-vision",
                    component: MissionVision
                }, {
                    path: "/marketing",
                    name: "marketing",
                    component: Marketing
                }, {
                    path: "/nuestros-valores",
                    name: "nuestros-valores",
                    component: NuestrosValores
                }, {
                    path: "/clientes",
                    name: "clientes",
                    component: Clientes
                }, {
                    path: "/contacto",
                    name: "contacto",
                    component: Contact
                }, {
                    path: "/articulos-oficina",
                    name: "articulos-oficina",
                    component: ArticulosOficina
                }, {
                    path: "/articulos-promocionales",
                    name: "articulos-promocionales",
                    component: ArticulosPromocionales
                }, {
                    path: "/ferias-publicidad",
                    name: "ferias-publicidad",
                    component: FeriasPublicidad
                }, {
                    path: "/servicios",
                    name: "servicios",
                    component: Servicios
                }, {
                    path: "/publicaciones",
                    name: "publicaciones",
                    component: NoticesPublic
                }, {
                    path: "/publicacion/:id",
                    name: "publicacion",
                    component: Publication
                }
            ]
        },
        {
            path: "/admin",
            component: () => import("../views/private/AppAdmin"),
            children: [
                {
                    path: "/admin",
                    name: "home-admin",
                    component: HomeAdmin
                }, {
                    path: "/admin/settings",
                    name: "settings",
                    component: Settings
                }, {
                    path: "/admin/messages",
                    name: "messages",
                    component: Messages
                }, {
                    path: "/admin/users",
                    name: "users",
                    component: Users
                }, {
                    path: "/admin/notices",
                    name: "notices",
                    component: Notices
                }
            ]
        },
        {
            path: "/login",
            component: () => import("../views/login/Login.vue"),
        }
    ]
});
router.beforeEach((to, from, next) => {
    const time = scrollRoute.methods.m_scroll_time(window.scrollY)
    scrollRoute.methods.m_scroll_animation(time)
    setTimeout(() => {
      next();
    }, time)
  })
export default router;
