import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Setting {
    static async getSetting() {
        const response = await axios.get(
            `${apiUrls.SETTING}/data`
        );
        return response.data;
    }
    static async updateSetting(parameters) {
        const response = await axios.put(
            `${apiUrls.SETTING}/update`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Setting;