<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/b_7.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.5), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home__body-container">
                <div>
                    <OFillInfoComp
                        v-for="(info, index) in listInfo"
                        :key="index"
                        :info="info"
                        class="space-bottom"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OFillInfoComp
} from "../../components/public/organisms";
export default {
    components: {
        OFillInfoComp
    },
    data: () => ({
        listInfo: [
            {
                img: "/imgs/marketing/carpetas_2.png",
                title: "Carpetas corporativas",
                listText: [
                    "La carpeta corporativa es parte esencial de la imagen institucional de cualquier empresa.",
                    `Son una excelente opción de recordación de marca, apostar por un diseño atractivo, garantizando
                    una línea de negocio coherente para la captación de clientes potenciales y respaldar a los actuales clientes.`
                ]
            }, {
                img: "/imgs/marketing/triptico_2.png",
                title: "Tríptico, díptico",
                listText: [
                    `Los trípticos son los folletos informativos que pueden doblarse en partes,
                    promociona un producto un evento aportando información breve y consolida
                    la imagen de su empresa.`,
                    `Los folletos pueden ayudar a su empresa ahorrándole una tonelada de dinero
                    en su anuncio promocional.`
                ]
            }, {
                img: "/imgs/marketing/tarjetas_2.png",
                title: "Tarjetas de representación",
                listText: [
                    `La tarjeta de presentación es una herramienta fundamental en el marketing
                    personal y de tu empresa, ayudan a las personas a recordar mejor nuestro
                    nombre y asociarlo con nuestra marca y todos los elementos que la rodean
                    como el logo y nuestros colores corporativos, ayudan a generar oportunidades
                    de negocios.`
                ]
            }, {
                img: "/imgs/marketing/adhesivos_2.png",
                title: "Adhesivo",
                listText: [
                    "Imprimí stickers con tu diseño, papel adhesivo cortes rectos y circulares, full color",
                    `Descubra lo fácil que es agregar su marca a todo lo que quiera reforzar y transmitir
                    la imagen de un negocio e incrementando al mismo tiempo la posibilidad de tener nuevos clientes.`
                ]
            }, {
                img: "/imgs/marketing/flyers_2.png",
                title: "Flyers",
                listText: [
                    `Un flyer publicitario o también conocido como folleto publicitario
                    es uno de los clásicos del marketing y la publicidad, contiene
                    un mensaje con el fin de transmitir, vender o promocionar un
                    producto o servicio por parte de empresas, pequeños negocios
                    o incluso organizadores de eventos.`,
                    `Los folletos pueden ayudar a su empresa ahorrándole una tonelada
                    de dinero en su anuncio promocional.`
                ]
            }
        ]
    })
}
</script>