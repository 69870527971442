<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formsetting"
    >
        <CFillFormComp
            :isMobile="isMobile"
            title="Teléfono:"
        >
            <MTextFieldComp
                v-model="setting.phone"
                :label="isMobile ? 'Teléfono' : ''"
                :counter="30"
                :rules="[
                    value => (`${value}`).length <= 30 || mRSmsExecedCounter(30)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Celular:"
        >
            <MTextFieldComp
                v-model="setting.cell_phone"
                :label="isMobile ? 'Celular' : ''"
                :counter="30"
                :rules="[
                    value => (`${value}`).length <= 30 || mRSmsExecedCounter(30)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Whatsapp:"
        >
            <MTextFieldComp
                v-model="setting.whatsapp"
                :label="isMobile ? 'Whatsapp' : ''"
                :counter="20"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 20 || mRSmsExecedCounter(20)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Dirección:"
        >
            <MTextFieldComp
                v-model="setting.address"
                :label="isMobile ? 'Dirección' : ''"
                :counter="50"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || mRSmsExecedCounter(50)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Correo:"
        >
            <MTextFieldComp
                v-model="setting.e_mail"
                :label="isMobile ? 'Correo' : ''"
                :counter="100"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 100 || mRSmsExecedCounter(100)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Facebook:"
        >
            <MTextFieldComp
                v-model="setting.facebook"
                :label="isMobile ? 'Facebook' : ''"
                :counter="200"
                :rules="[
                    value => (`${value}`).length <= 200 || mRSmsExecedCounter(200)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Instagram:"
        >
            <MTextFieldComp
                v-model="setting.instagram"
                :label="isMobile ? 'Instagram' : ''"
                :counter="200"
                :rules="[
                    value => (`${value}`).length <= 200 || mRSmsExecedCounter(200)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Twitter:"
        >
            <MTextFieldComp
                v-model="setting.twitter"
                :label="isMobile ? 'Twitter' : ''"
                :counter="200"
                :rules="[
                    value => (`${value}`).length <= 50 || mRSmsExecedCounter(50)
                ]"
            />
        </CFillFormComp>
        <div class="text-center">
            <MBtnNormalComp
                title="Guardar"
                @click="eValidateForm()"
            />
        </div>
    </v-form>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../../molecules";
import {
    CFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        CFillFormComp,
        MBtnNormalComp
    },
    props: {
        isMobile: Boolean,
        setting: Object
    },
    data: () => ({
        valid: true,

    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formsetting.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formsetting.resetValidation();
        }
    }
}
</script>