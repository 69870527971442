var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formsetting",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Teléfono:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Teléfono' : '',"counter":30,"rules":[
                value => (`${value}`).length <= 30 || _vm.mRSmsExecedCounter(30)
            ]},model:{value:(_vm.setting.phone),callback:function ($$v) {_vm.$set(_vm.setting, "phone", $$v)},expression:"setting.phone"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Celular:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Celular' : '',"counter":30,"rules":[
                value => (`${value}`).length <= 30 || _vm.mRSmsExecedCounter(30)
            ]},model:{value:(_vm.setting.cell_phone),callback:function ($$v) {_vm.$set(_vm.setting, "cell_phone", $$v)},expression:"setting.cell_phone"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Whatsapp:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Whatsapp' : '',"counter":20,"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 20 || _vm.mRSmsExecedCounter(20)
            ]},model:{value:(_vm.setting.whatsapp),callback:function ($$v) {_vm.$set(_vm.setting, "whatsapp", $$v)},expression:"setting.whatsapp"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Dirección:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Dirección' : '',"counter":50,"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 50 || _vm.mRSmsExecedCounter(50)
            ]},model:{value:(_vm.setting.address),callback:function ($$v) {_vm.$set(_vm.setting, "address", $$v)},expression:"setting.address"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Correo:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Correo' : '',"counter":100,"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 100 || _vm.mRSmsExecedCounter(100)
            ]},model:{value:(_vm.setting.e_mail),callback:function ($$v) {_vm.$set(_vm.setting, "e_mail", $$v)},expression:"setting.e_mail"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Facebook:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Facebook' : '',"counter":200,"rules":[
                value => (`${value}`).length <= 200 || _vm.mRSmsExecedCounter(200)
            ]},model:{value:(_vm.setting.facebook),callback:function ($$v) {_vm.$set(_vm.setting, "facebook", $$v)},expression:"setting.facebook"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Instagram:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Instagram' : '',"counter":200,"rules":[
                value => (`${value}`).length <= 200 || _vm.mRSmsExecedCounter(200)
            ]},model:{value:(_vm.setting.instagram),callback:function ($$v) {_vm.$set(_vm.setting, "instagram", $$v)},expression:"setting.instagram"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Twitter:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Twitter' : '',"counter":200,"rules":[
                value => (`${value}`).length <= 50 || _vm.mRSmsExecedCounter(50)
            ]},model:{value:(_vm.setting.twitter),callback:function ($$v) {_vm.$set(_vm.setting, "twitter", $$v)},expression:"setting.twitter"}})],1),_c('div',{staticClass:"text-center"},[_c('MBtnNormalComp',{attrs:{"title":"Guardar"},on:{"click":function($event){return _vm.eValidateForm()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }