<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_3.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="contact__container space-top space-bottom">
            <div class="contact__menu">
                <div>
                    <div class="contact__options-tab">
                        <v-tabs vertical v-model="tab">
                            <v-tab style="text-transform: none; justify-content: left;">
                                <v-icon left>
                                    mdi-account
                                </v-icon>
                                <h1 class="app-public-subtitle">Llegada</h1>
                            </v-tab>
                            <v-tab style="text-transform: none; justify-content: left;">
                                <v-icon left>
                                    mdi-lock
                                </v-icon>
                                <h1 class="app-public-subtitle">Contacto</h1>
                            </v-tab>
                        </v-tabs>
                    </div>
                    <div class="contact__info">
                        <div class="contact__info-data">
                            <h1 class="app-public-title">Datos de Contacto</h1>
                            <h1 class="app-public-subtitle">
                                {{`Calle: ${setting.address}`}}
                            </h1>
                            <h1 class="app-public-subtitle">
                                {{`Telf: ${setting.phone}`}}
                            </h1>
                            <h1 class="app-public-subtitle">
                                {{`Cel.: ${setting.cell_phone}`}}
                            </h1>
                            <h1
                                v-if="setting.e_mail != null"
                                class="app-public-subtitle"
                            >
                                {{`${setting.e_mail}`}}
                            </h1>
                        </div>
                        <v-divider vertical class="contact__info-line"></v-divider>
                        <div style="width:160px;">
                            <h1 class="app-public-title mt-1">Redes Sociales</h1>
                            <div class="d-flex" v-if="setting.facebook != null">
                                <v-icon small class="mr-2">mdi-facebook</v-icon>
                                <div
                                    style="cursor:pointer"
                                    @click="eOpenNetworkSocial(setting.facebook)"
                                >
                                    <h1 class="app-public-subtitle">Facebook</h1>
                                </div>
                            </div>
                            <div class="d-flex" v-if="setting.twitter != null">
                                <v-icon small class="mr-2">mdi-twitter</v-icon>
                                <div
                                    style="cursor:pointer"
                                    @click="eOpenNetworkSocial(setting.twitter)"
                                >
                                    <h1 class="app-public-subtitle">Twitter</h1>
                                </div>
                            </div>
                            <div class="d-flex" v-if="setting.instagram != null">
                                <v-icon small class="mr-2">mdi-instagram</v-icon>
                                <div
                                    style="cursor:pointer"
                                    @click="eOpenNetworkSocial(setting.instagram)"
                                >
                                    <h1 class="app-public-subtitle">Instagram</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact__options-tab-mobile">
                <div>
                    <v-tabs v-model="tab">
                        <v-tab style="text-transform: none; justify-content: left;">
                            <v-icon left>
                                mdi-account
                            </v-icon>
                            <h1 class="app-public-subtitle">Llegada</h1>
                        </v-tab>
                        <v-tab style="text-transform: none; justify-content: left;">
                            <v-icon left>
                                mdi-lock
                            </v-icon>
                            <h1 class="app-public-subtitle">Contacto</h1>
                        </v-tab>
                    </v-tabs>
                </div>
            </div>
            <div class="contact__data">
                <v-tabs v-model="tab">
                    <v-tab-item>
                        <div class="contact__map">
                            <div class="contact__map-google">
                                <iframe
                                    class="contact__map-iframe"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d564.6938720465321!2d-63.17169157026148!3d-17.801450236510966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93f1e844650a8749%3A0x2ed3feaf744025ee!2sinfo%20graficasalgis%20com%20bo!5e0!3m2!1ses!2sbo!4v1680575540466!5m2!1ses!2sbo"
                                    style="border:0;"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="d-flex justify-center">
                            <OFormContactComp
                                :dataMessage="dataMessage"
                                @clickform="eValidForm"
                                ref="formcontactcomp"
                            />
                        </div>
                    </v-tab-item>
                </v-tabs>
            </div>
        
        </div>
        <CSmsActionComp
            :value="frameAction"
            :smsAction="smsAction"
            :colorMessage="colorMessage"
            :timeSuccess="timeSuccess"
            @clickaccion="resetearSmsAction()"
        />
        <CLoaderOperationComp
            :value="dialogOperation"
            smsOperation="Enviando Mensaje"
        />
    </div>
</template>
<script>
import {
    OFormContactComp
} from "../../components/public/organisms";
import {
    CSmsActionComp,
    CLoaderOperationComp
} from "../../components/private/cells";
import {
    Message
} from "../../models";
import {
    animationScrollMixin
} from "../../mixins";
export default {
    mixins: [
        animationScrollMixin
    ],
    props: {
        setting: Object
    },
    components: {
        OFormContactComp,
        CSmsActionComp,
        CLoaderOperationComp
    },
    data: () => ({
        tab: 1,
        dataMessage: new Message,
        frameAction: false,
        timeSuccess: 3000,
        smsAction: [],
        colorMessage: "",
        dialogOperation: false
    }),
    methods: {
        resetearSmsAction() {
            clearTimeout(this.timeSuccess);
            this.frameAction = false;
        },
        showSmsAction(smsAction = [], color = "success") {
            this.resetearSmsAction();
            setTimeout(() => {
                this.timeSuccess = 3000;
                this.smsAction = smsAction;
                this.frameAction = true;
                this.colorMessage = color;
                setTimeout(() => {
                    this.frameAction = false;
                }, 3000);
            }, 150);
        },
        async sendMessage() {
            this.dialogOperation = true;
            try {
                await Message.addMessage(this.dataMessage);
                this.dataMessage = new Message();
                this.$refs.formcontactcomp.resetForm();
                const smsError = ['Envio Exitoso', 'Gracias por tu solicitud nos contactaremos contigo'];
                this.dialogOperation = false;
                this.showSmsAction(smsError, "success");
            } catch (error) {
                console.log(error);
                this.dialogOperation = false;
                const smsError = ['Error en el servidor', 'Intente de nuevo porfavor'];
                this.showSmsAction(smsError, "error");
            }
        },
        eValidForm(isValidForm) {
            const smsError = ['Error de Datos', 'Llene el formulario'];
            if (!isValidForm) this.showSmsAction(smsError, "error");
            else {
                const time = this.mASTime();
                this.$vuetify.goTo(0, this.mASOpctions(time));
                setTimeout(() => {
                    this.sendMessage();
                }, time);
            }
        },
        eOpenNetworkSocial(link) {
            window.open(link);
        }
    }
}
</script>