<template>
    <div class="home">
        <v-card
            width="100%"
            elevation="0"
            class="border-0"
            color="transparent"
        >
            <CBannerComp />
        </v-card>
        <div class="d-flex justify-center flex-wrap space-top">
            <h1 class="app-public-big-title color-secondary">
                Industrias
            </h1>
            <div class="d-flex align-center">
                <h1 class="app-public-big-title color-primary ml-2 mr-2">
                    Gráficas
                </h1>
                <div class="d-flex align-center">
                    <div class="home__logo-title">
                        <v-img
                            src="/imgs/logos/logo_u1.png"
                            width="100%"
                            contain
                        ></v-img>
                    </div>
                </div>
            </div>
        </div>
        <CInfoHomeComp />
        <div class="d-flex justify-center space-top">
            <div class="home__cards d-flex justify-center flex-wrap">
                <OCardHomeComp
                    v-for="(product, index) in imgProducts"
                    :key="product.id"
                    :product="product"
                    :index="index"
                />
            </div>
        </div>
        <v-card
            width="100%"
            elevation="0"
            class="rounded-0 mt-10"
            color="transparent"
        >
            <v-img
                src="/imgs/banners/u_3.jpg"
                class="home__image-message"
                gradient="to top right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)"
            >
                <div class="d-flex align-center justify-center" style="height:100%">
                    <div>
                        <h1 class="app-public-text-image text-center">
                            En gráficas ALGIS ponemos al servicio de nuestros clientes una amplia experiencia.
                        </h1>
                        <div class="d-flex justify-center mt-4">
                            <v-btn
                                color="red"
                                dark
                                to="/contacto"
                            >CONTACTANOS</v-btn>
                        </div>
                    </div>
                </div>
            </v-img>
        </v-card>
    </div>
</template>
<script>
import {
    CBannerComp,
    CInfoHomeComp
} from "../../components/public/cells";
import {
    OCardHomeComp
} from "../../components/public/organisms";
export default {
    components: {
        CBannerComp,
        OCardHomeComp,
        CInfoHomeComp
    },
    data: () => ({
        imgOne: "/imgs/home/ornament_one.jpg",
        imgTwo: "/imgs/home/ornament_two.jpg",
        imgThree: "/imgs/home/ornament_three.jpg",
        imgProducts: [
            {
                title: "Adhesivo",
                image: "/imgs/home/adhesivo.jpg"
            }, {
                title: "Brochure",
                image: "/imgs/home/brochure.jpg"
            }, {
                title: "Calendarios",
                image: "/imgs/home/calendario.jpg"
            }, {
                title: "Carpetas",
                image: "/imgs/home/carpetas.jpg"
            }, {
                title: "Cartillas",
                image: "/imgs/home/cartillas.jpg"
            }, {
                title: "Membretes",
                image: "/imgs/home/membrete.jpg"
            }, {
                title: "Tarjetas",
                image: "/imgs/home/tarjetas.jpg"
            }, {
                title: "Volantes",
                image: "/imgs/home/volantes.jpg"
            }
        ]
    })
}
</script>