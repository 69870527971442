<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="o-menu-mobile"
            height="55"
        >
            <div class="d-flex align-center" style="width:100%">
                <div class="d-flex mt-1">
                    <router-link
                        class="d-flex align-center"
                        to="/"
                    >
                        <v-img
                            src="/imgs/logos/logo_u1.png"
                            width="90px"
                            class="mr-2"
                        ></v-img>
                    </router-link>
                </div>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon
                    @click="drawer=true"
                    class="cd-pu-color"
                ></v-app-bar-nav-icon>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            width="360"
            class="cf-pu-background"
        >
            <v-toolbar class="o-menu-mobile__header">
                <div class="mt-1">
                    <v-img
                        src="/imgs/logos/logo_u1.png"
                        width="90"
                        max-width="90"
                        class="mr-2"
                    ></v-img>
                </div>
                <v-spacer></v-spacer>
                <v-icon @click="drawer=!drawer" color="black">
                    mdi-close-circle
                </v-icon>
            </v-toolbar>
            <v-list nav class="pb-0 pt-2 pb-1">
                <v-list-item-group color="primary">
                    <v-list-item
                        @click="drawer=!drawer"
                        to="/"
                        active-class="o-menu-mobile__activate-option"
                    >
                        <v-list-item-title>
                            <h1 class="app-public-title">Inicio</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list nav class="pb-0 pt-0">
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                >
                    <v-list-group
                        v-if="option.submenu.length>0"
                        :ripple="false"
                        class="mb-1"
                        color="primary"
                    >
                        <template v-slot:activator>
                            <v-list-item-title>
                                <h1 class="app-public-title">{{option.title}}</h1>
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(child, index) in option.submenu"
                            :key="index"
                            class="pl-8"
                            :to="child.route"
                            @click="drawer=!drawer"
                            :ripple="false"
                            active-class="o-menu-mobile__activate-option"
                        >
                            <v-list-item-title class="d-flex align-center">
                                <h1 class="app-public-subtitle">{{child.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item-group
                        v-else
                        color="primary"
                    >
                        <v-list-item
                            :to="option.route"
                            @click="drawer=!drawer"
                            class="mb-1"
                            :ripple="false"
                            active-class="o-menu-mobile__activate-option"
                        >
                            <v-list-item-title>
                                <h1 class="app-public-title">{{option.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    props: {
        listMenu: Array
    },
    data: () => ({
        drawer: false
    })
}
</script>
