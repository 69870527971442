<template>
    <v-btn
        :ripple="false"
        outlined
        class="p-color-primary"
        small
        @click="$emit('click')"
    >
        {{title}}
    </v-btn>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>