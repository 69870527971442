<template>
    <div class="o-menu-pc">
        <div class="background-primary pa-1 d-flex align-center">
            <v-icon
                class="color-dark"
                style="font-size:18px; margin-top: -2px;"
            >mdi-whatsapp</v-icon>
            <h1 class="app-menu-text-dark ml-1">
                {{setting.cell_phone}}
            </h1>
        </div>
        <div class="d-flex">
            <div class="d-flex align-center o-menu-pc__container">
                <router-link to="/">
                    <div class="o-menu-pc__logo">
                        <v-img
                            src="/imgs/logos/logo_u1.png"
                            width="100%"
                            height="100%"
                        ></v-img>
                    </div>
                </router-link>
                <v-spacer></v-spacer>
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                >
                    <div v-if="option.submenu.length > 0" class="mr-1">
                        <v-menu
                            transition="slide-x-transition"
                            offset-y
                            open-on-hover
                        >
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                    style="text-transform:none !important"
                                    text
                                    :ripple="false"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <h1 class="app-menu-text">{{option.title}}</h1>
                                </v-btn>
                            </template>
                            <v-list class="pa-0 ma-0 pb-1 pt-1 o-menu-pc__list">
                                <v-list-item-group
                                    color="primary"
                                >
                                    <v-list-item
                                        v-for="(suboption, index) in option.submenu"
                                        :key="index"
                                        :to="suboption.route"
                                        :ripple="false"
                                        class="ma-1 d-flex o-menu-pc__submenu"
                                        active-class="o-menu-pc__option-submenu"
                                    >
                                        <h1 class="app-menu-text">{{suboption.title}}</h1>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                    <div v-else class="mr-1">
                        <v-btn
                            style="text-transform:none !important;"
                            text
                            :ripple="false"
                            :to="option.route"
                            active-class="o-menu-pc__option"
                        >
                            <h1 class="app-menu-text">{{option.title}}</h1>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        listMenu: Array,
        setting: Object
    }
}
</script>