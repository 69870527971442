export default {
    methods: {
        mDFormat(date) {
            if (date === null) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        mDSystem(type_format) {
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();
            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;
            if (type_format) return (today = dd + "/" + mm + "/" + yyyy);
            return (today = yyyy + "-" + mm + "-" + dd);
        },
        mDDia(date) {
            if (date === null) return null;
            const [day] = date.split("/");
            return `${day}`;
        },
        mDMonth(date) {
            if (date === null) return null;
            const month = date[3] + date[4];
            switch (month) {
                case "01": return "ENE";
                case "02": return "FEB";
                case "03": return "MAR";
                case "04": return "ABR";
                case "05": return "MAY";
                case "06": return "JUN";
                case "07": return "JUL";
                case "08": return "AGO";
                case "09": return "SEP";
                case "10": return "OCT";
                case "11": return "NOV";
                case "12": return "DIC";
            }
            return "DIC";
        }
    }
}
