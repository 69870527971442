<template>
    <div class="c-banner">
        <v-carousel
            cycle
            height="100%"
            hide-delimiter-background
            show-arrows-on-hover
        >
            <v-carousel-item>
                <v-img
                    src="/imgs/banners/u_1.jpg"
                    height="100%"
                    width="100%"
                >
                    <div class="d-flex align-center" style="height:100%">
                        <div class="c-banner__text">
                            <h1
                                v-if="!isMobile"
                                class="c-banner__title color-dark mr-2"
                            >
                                Somos una empresa que necesitas, porque a pesar de la
                            </h1>
                            <h1
                                v-if="!isMobile"
                                class="c-banner__title color-dark mr-2"
                            >
                                competencia, trabajamos para darte el mejor servicio
                            </h1>
                            <h1
                                v-if="isMobile"
                                class="c-banner__title text-center color-dark mr-2"
                            >
                                Somos una empresa que necesitas, porque a pesar de la competencia, trabajamos para
                                darte el mejor servicio
                            </h1>
                        </div>
                    </div>
                </v-img>
            </v-carousel-item>
            <v-carousel-item>
                <v-img
                    src="/imgs/banners/u_2.jpg"
                    height="100%"
                    width="100%"
                >
                    <div class="d-flex align-center" style="height:100%">
                        <div class="c-banner__text">
                            <h1
                                v-if="!isMobile"
                                class="c-banner__title-two mr-2"
                            >
                                Con nosotros encontraras el soporte adecuado
                            </h1>
                            <h1
                                v-if="!isMobile"
                                class="c-banner__title-two mr-2"
                            >
                                para llevar a cabo sus proyectos gráficos
                            </h1>
                            <h1
                                v-if="isMobile"
                                class="c-banner__title text-center color-dark mr-2"
                            >
                                Con nosotros encontraras el soporte adecuado para llevar a cabo sus proyectos gráficos
                            </h1>
                        </div>
                    </div>
                </v-img>
            </v-carousel-item>
            <v-carousel-item>
                <v-img
                    src="/imgs/banners/b_4.jpg"
                    height="100%"
                    width="100%"
                >
                    <div class="d-flex align-center" style="height:100%">
                        <div class="c-banner__text-two">
                            <h1
                                v-if="!isMobile"
                                class="c-banner__title-three mr-2"
                            >
                                Estamos comprometidos con nuestro
                            </h1>
                            <h1
                                v-if="!isMobile"
                                class="c-banner__title-three mr-2"
                            >
                                lineamiento, cliente y medio ambiente
                            </h1>
                            <h1
                                v-if="isMobile"
                                class="c-banner__title text-center color-dark mr-2"
                            >
                                Estamos comprometidos con nuestro lineamiento, cliente y medio ambiente
                            </h1>
                        </div>
                    </div>
                </v-img>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>
<script>
export default {
    data: () => ({
        isMobile: false
    }),
    mounted() {
        if (window.innerWidth <= 700) this.isMobile = true;
    }
}
</script>