<template>
    <div class="o-table-messages animation-opacity">
        <table class="o-table-messages__table">
            <thead>
                <tr>
                    <th width="50">
                        <h1 class="app-private-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Nombre</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Usuario</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Estado</h1>
                    </th>
                    <th width="160">
                        <h1 class="app-private-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(user, index) in listUsers"
                    :key="index"
                >
                    <td>
                        <h1 class="app-private-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{user.name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{user.username}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="user.enabled == 1 ? 'user__success' : 'user__error'"
                            ></div>
                            <h1 class="app-private-subtitle ml-1">
                                {{user.enabled == 1 ? 'Habilitado' : 'Inhabilitado'}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center">
                            <CBtnToolTipTableComp
                                smsToolTip="Editar Usuario"
                                icon="mdi-pencil"
                                @click="$emit('clickedit', user)"
                            />
                            <CBtnToolTipTableComp
                                :smsToolTip="user.enabled == 1
                                    ? `Inhabilitar ${user.name}`
                                    : `Habilitar ${user.name}`"
                                :icon="user.enabled == 1
                                    ? 'mdi-eye-off'
                                    : 'mdi-eye'"
                                @click="$emit('clickstate', user)"
                            />
                            <CBtnToolTipTableComp
                                smsToolTip="Cambiar credenciales"
                                icon="mdi-account-lock"
                                @click="$emit('clickcredentials', user)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listUsers.length === 0">
                    <td colspan="5">
                        <h1 class="app-private-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    CBtnToolTipTableComp
} from "../../cells";
export default {
    props: {
        listUsers: Array
    },
    components: {
        CBtnToolTipTableComp
    }
}
</script>