<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formuser"
    >
        <CFillFormComp
            v-if="numberOperation != 2"
            :isMobile="isMobile"
            title="Nombre:"
        >
            <MTextFieldComp
                v-model="dataUser.name"
                :label="isMobile ? 'Nombre' : ''"
                :counter="30"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 30 || mRSmsExecedCounter(30)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            v-if="numberOperation == 0 || numberOperation == 2"
            :isMobile="isMobile"
            title="Usuario:"
        >
            <MTextFieldComp
                v-model="dataUser.username"
                :label="isMobile ? 'Usuario' : ''"
                :counter="30"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 30 || mRSmsExecedCounter(30)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            v-if="numberOperation == 0 || numberOperation == 2"
            :isMobile="isMobile"
            title="Contraseña:"
        >
            <MTextFieldComp
                v-model="dataUser.password"
                :type="showPassword ? 'text' : 'password'"
                :label="isMobile ? 'Contraseña' : ''"
                :counter="20"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => !mRWithSpaces(value) || smsWithoutSpace,
                    value => (`${value}`).length <= 20 || mRSmsExecedCounter(20)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            v-if="numberOperation == 0 || numberOperation == 2"
            :isMobile="isMobile"
            title="Repita Contraseña:"
        >
            <MTextFieldComp
                v-model="dataUser.repit_password"
                :type="showPassword ? 'text' : 'password'"
                :label="isMobile ? 'Repita Contraseña' : ''"
                :counter="20"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                :rules="[
                    value => !mRIsEmpty(value) || smsEmpty,
                    value => !mRWithSpaces(value) || smsWithoutSpace,
                    value => mREquals(value, dataUser.password) || 'Las contraseñas no coinciden',
                    value => (`${value}`).length <= 20 || mRSmsExecedCounter(20)
                ]"
            />
        </CFillFormComp>
        <div class="text-center">
            <MBtnNormalComp
                title="Guardar"
                @click="eValidateForm()"
            />
        </div>
    </v-form>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../../molecules";
import {
    CFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        CFillFormComp,
        MBtnNormalComp
    },
    props: {
        isMobile: Boolean,
        dataUser: Object,
        numberOperation: Number
    },
    data: () => ({
        valid: true,
        showPassword: false
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formuser.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formuser.resetValidation();
        }
    }
}
</script>