<template>
    <div class="o-table-messages animation-opacity">
        <table class="o-table-messages__table">
            <thead>
                <tr>
                    <th width="50">
                        <h1 class="app-private-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Nombre</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Asunto</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Correo</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Celular</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Comentario</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Información</h1>
                    </th>
                    <th>
                        <h1 class="app-private-title-dark">Fecha</h1>
                    </th>
                    <th width="160">
                        <h1 class="app-private-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(message, index) in listMessages"
                    :key="index"
                >
                    <td>
                        <h1 class="app-private-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{message.name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{message.affair}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{message.e_mail}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{message.phone}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{message.comment}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{message.info}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-private-subtitle">
                            {{`${message.hour} ${message.date}`}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center">
                            <CBtnToolTipTableComp
                                smsToolTip="Eliminar mensaje"
                                icon="mdi-delete"
                                @click="$emit('clickdelete', message)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listMessages.length === 0">
                    <td colspan="9">
                        <h1 class="app-private-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    CBtnToolTipTableComp
} from "../../cells";
export default {
    props: {
        listMessages: Array
    },
    components: {
        CBtnToolTipTableComp
    }
}
</script>