<template>
    <div>
        <div class="pa-2 d-flex align-center" style="height:48px">
            <h1 class="app-private-title">INFORMACIÓN</h1>
            <v-spacer></v-spacer>
            <CBtnToolTipHeaderComp
                icon="mdi-database"
                smsToolTip="Volver atrás"
                @click="tab = 0"
            />
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <CLoaderDataComp
                    v-if="dialogLoaderData"
                />
                <MWithoutDataComp
                    class="ma-2"
                    v-if="!dialogLoaderData && isMobile && setting.id == undefined"
                />
                <div
                    v-if="setting.id != undefined"
                    class="pa-2"
                >
                    <MBtnNormalComp
                        title="Actualizar"
                        class="mb-2"
                        @click="eOpenFormUpdate()"
                    />
                    <CFillInfoComp
                        title="Teléfono"
                        :subtitle="setting.phone"
                    />
                    <CFillInfoComp
                        title="Celular"
                        :subtitle="setting.cell_phone"
                    />
                    <CFillInfoComp
                        title="Whatsapp"
                        :subtitle="setting.whatsapp"
                    />
                    <CFillInfoComp
                        title="Dirección"
                        :subtitle="setting.address"
                    />
                    <CFillInfoComp
                        title="E-mail"
                        :subtitle="setting.e_mail != null ? setting.e_mail : 'S/E'"
                    />
                    <CFillInfoComp
                        v-if="setting.facebook != null"
                        title="Facebook"
                        :subtitle="setting.facebook"
                    >
                        <CBtnToolTipHeaderComp
                            icon="mdi-facebook"
                            class="ml-1"
                            smsToolTip="Abrir facebook"
                            @click="eOpenLink(setting.facebook)"
                        />
                    </CFillInfoComp>
                    <CFillInfoComp
                        class="mt-1"
                        v-if="setting.instagram != null"
                        title="Instagram"
                        :subtitle="setting.instagram"
                    >
                        <CBtnToolTipHeaderComp
                            icon="mdi-instagram"
                            class="ml-1"
                            smsToolTip="Abrir instragram"
                            @click="eOpenLink(setting.instagram)"
                        />
                    </CFillInfoComp>
                    <CFillInfoComp
                        class="mt-1"
                        v-if="setting.twitter != null"
                        title="Twitter"
                        :subtitle="setting.twitter"
                    >
                        <CBtnToolTipHeaderComp
                            icon="mdi-twitter"
                            class="ml-1"
                            smsToolTip="Abrir twitter"
                            @click="eOpenLink(setting.twitter)"
                        />
                    </CFillInfoComp>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <SSkeletonFormComp
                        title="Actualizar"
                    >
                        <OFormSettingComp
                            :setting="settingUpdate"
                            :isMobile="isMobile"
                            @clickform="eValidateForm"
                        />
                    </SSkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OGrupoGeneralComp
            :dialogConfirmation="dialogConfirmation"
            :smsConfirmation="smsConfirmation"
            :dialogError="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            :smsAction="smsAction"
            :frameAction="frameAction"
            :colorMessage="colorMessage"
            :dialogLoaderOperation="dialogLoaderOperation"
            :timeSuccess="timeSuccess"
            @clickaction="mDGResetearSmsAction()"
            @clickerror="eCloseError()"
            @clickyes="eUpdateSettings()"
            @clickno="dialogConfirmation = false"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MWithoutDataComp,
    MBtnNormalComp
} from "../../components/private/molecules";
import {
    CLoaderDataComp,
    CFillInfoComp,
    CBtnToolTipHeaderComp
} from "../../components/private/cells";
import {
    OFormSettingComp
} from "../../components/private/organisms/forms";
import {
    OGrupoGeneralComp
} from "../../components/private/organisms/others";
import {
    SSkeletonFormComp
} from "../../components/private/species";
import {
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServer,
    userMixin
} from "../../mixins";
import {
    Setting
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServer,
        userMixin
    ],
    components: {
        MLineHeaderComp,
        CLoaderDataComp,
        MWithoutDataComp,
        CFillInfoComp,
        CBtnToolTipHeaderComp,
        MBtnNormalComp,
        OFormSettingComp,
        SSkeletonFormComp,
        OGrupoGeneralComp
    },
    data: () => ({
        setting: {},
        settingUpdate: {},
        tab: 0
    }),
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.smsError = this.mRSSmsError(error);
            this.actionError = this.mRSValueAction(error);
            this.withoutAccess = this.mRSAutorization(error);
            this.dialogError = true;
        },
        eCloseError() {
            this.dialogError = false;
        },
        async getSetting() {
            this.dialogLoaderData = true;
            try {
                const response = await Setting.getSetting();
                this.setting = response;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenFormUpdate() {
            this.settingUpdate = Object.assign({}, this.setting);
            this.tab = 1;
        },
        eValidateForm(isValidForm) {
            const time = this.mASTime();
            this.$vuetify.goTo(0, this.mASOpctions(time));
            setTimeout(() => {
                if (!isValidForm) this.mDGShowError();
                else {
                    this.smsConfirmation = [`¿Está seguro(a) de actualizar los datos?`];
                    this.dialogConfirmation = true;
                }
            }, time);
        },
        async eUpdateSettings() {
            this.dialogConfirmation = false;
            this.dialogLoaderOperation = true;
            try {
                const response = await Setting.updateSetting(this.settingUpdate);
                Object.assign(this.setting, response);
                this.tab = 0;
                this.dialogLoaderOperation = false;
                this.mDGShowSmsAction(["Operación Exitosa", "Se actualizó la información"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenLink(link) {
            window.open(link);
        }
    },
    mounted() {
        this.getSetting();
    }
}
</script>