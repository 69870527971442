var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formnotice",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.indexNotice == -1)?_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('CPhotoComp',{ref:"photocomp",attrs:{"referenceImg":"fotografia","title":"FOTOGRAFÍA","subtitle":"Imágen","withImgDB":false},on:{"changePhoto":_vm.ePhotoSelected}})],1):_vm._e(),_c('CFillNoticeFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Título:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Título' : '',"counter":100,"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 100 || _vm.mRSmsExecedCounter(100)
            ]},model:{value:(_vm.dataNotice.title),callback:function ($$v) {_vm.$set(_vm.dataNotice, "title", $$v)},expression:"dataNotice.title"}})],1),_c('CFillNoticeFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Descripción:"}},[_c('MTextareaComp',{attrs:{"label":_vm.isMobile ? 'Descripción' : '',"rules":[
                value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataNotice.description),callback:function ($$v) {_vm.$set(_vm.dataNotice, "description", $$v)},expression:"dataNotice.description"}})],1),_c('CFillNoticeFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Link Uno:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Link Uno' : '',"counter":200,"rules":[
                value => (`${value}`).length <= 200 || _vm.mRSmsExecedCounter(200)
            ]},model:{value:(_vm.dataNotice.link_one),callback:function ($$v) {_vm.$set(_vm.dataNotice, "link_one", $$v)},expression:"dataNotice.link_one"}})],1),_c('CFillNoticeFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Link Dos:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Link Dos:' : '',"counter":200,"rules":[
                value => (`${value}`).length <= 200 || _vm.mRSmsExecedCounter(200)
            ]},model:{value:(_vm.dataNotice.link_two),callback:function ($$v) {_vm.$set(_vm.dataNotice, "link_two", $$v)},expression:"dataNotice.link_two"}})],1),_c('div',{staticClass:"text-center"},[_c('MBtnNormalComp',{attrs:{"title":"Guardar"},on:{"click":function($event){return _vm.eValidateForm()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }