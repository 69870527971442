<template>
    <div>
        <div class="mission-vision__fond">
            <v-img
                src="/imgs/banners/o_8.jpg"
                width="100%"
                height="100%"
                gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.05)"
            ></v-img>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home__body-container">
                <div>
                    <OFillInfoComp
                        v-for="(info, index) in listInfo"
                        :key="index"
                        :info="info"
                        class="space-bottom"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OFillInfoComp
} from "../../components/public/organisms";
export default {
    components: {
        OFillInfoComp
    },
    data: () => ({
        listInfo: [
            {
                img: "/imgs/promocionales/volantes.png",
                title: "Volantes",
                listText: [
                    `Los volantes publicitarios ayudan a las empresas a
                    comercializar sus productos o servicios, el contenido
                    principal del folleto debe ser conciso y fácil de leer,
                    poseer la calidad adecuada para llamar la atención de tus
                    consumidores y a su vez tener un costo de impresión rentable
                    para tu empresa.`
                ]
            },
            {
                img: "/imgs/promocionales/triptico.png",
                title: "Trípticos y Díticos",
                listText: [
                    `Los trípticos son los folletos informativos que pueden doblarse
                    en partes, promociona un producto un evento aportando 
                    información breve y consolida la imagen de su empresa.`
                ]
            },
            {
                img: "/imgs/promocionales/sobres.png",
                title: "Sobres",
                listText: [
                    `Los sobres son las herramientas con las cuales puedes diferenciarte
                    de tus competidores e impresionar tus clientes de manera positiva,
                    siempre más atentos a los productos y a las empresas, la
                    personalización es una de las soluciones que puedes elegir para
                    destacarte de los demás. Imprimir tu logo, tu slogan, el nombre
                    de tu empresa en tus sobres hace que tus clientes tengan buena
                    impresión de la empresa.`
                ]
            },
            {
                img: "/imgs/promocionales/carpetas2.png",
                title: "Carpetas Corporativas",
                listText: [
                    "La carpeta corporativa es parte esencial de la imagen institucional de cualquier empresa.",
                    `Son una excelente opción de recordación de marca, apostar por un diseño atractivo, garantizando
                    una línea de negocio coherente para la captación de clientes potenciales y respaldar a los actuales clientes.`
                ]
            }, {
                img: "/imgs/promocionales/flyers.png",
                title: "Flyers",
                listText: [
                    `Un flyer publicitario o también conocido como folleto publicitario
                    es uno de los clásicos del marketing y la publicidad, contiene
                    un mensaje con el fin de transmitir, vender o promocionar un
                    producto o servicio por parte de empresas, pequeños negocios
                    o incluso organizadores de eventos.`,
                    `Los folletos pueden ayudar a su empresa ahorrándole una tonelada
                    de dinero en su anuncio promocional.`
                ]
            },
            {
                img: "/imgs/promocionales/tarjetas_4.png",
                title: "Tarjetas",
                listText: [
                    `La tarjeta de presentación es una herramienta fundamental en 
                    el marketing personal y de tu empresa, ayudan a las personas
                    a recordar mejor nuestro nombre y asociarlo con nuestra marca
                    y todos los elementos que la rodean como el logo y nuestros
                    colores corporativos, ayudan a generar oportunidades de
                    negocios.`
                ]
            }
        ]
    })
}
</script>