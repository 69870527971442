var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formcontact",staticClass:"o-form-contact",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',[_c('h1',{staticClass:"app-public-big-title text-center mb-3"},[_vm._v("Pedir Presupuesto")]),_c('div',{staticClass:"o-form-contact__fill"},[_c('div',{staticClass:"o-form-contact__fill-input"},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre","outlined":"","rules":[
                        value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
                    ]},model:{value:(_vm.dataMessage.name),callback:function ($$v) {_vm.$set(_vm.dataMessage, "name", $$v)},expression:"dataMessage.name"}})],1),_c('div',{staticClass:"o-form-contact__fill-input"},[_c('v-text-field',{attrs:{"dense":"","label":"Asunto","outlined":"","rules":[
                        value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
                    ]},model:{value:(_vm.dataMessage.affair),callback:function ($$v) {_vm.$set(_vm.dataMessage, "affair", $$v)},expression:"dataMessage.affair"}})],1)]),_c('div',{staticClass:"o-form-contact__fill mt-2"},[_c('div',{staticClass:"o-form-contact__fill-input"},[_c('v-text-field',{attrs:{"dense":"","label":"Correo Electrónico","outlined":"","rules":[
                        value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
                    ]},model:{value:(_vm.dataMessage.e_mail),callback:function ($$v) {_vm.$set(_vm.dataMessage, "e_mail", $$v)},expression:"dataMessage.e_mail"}})],1),_c('div',{staticClass:"o-form-contact__fill-input"},[_c('v-text-field',{attrs:{"dense":"","label":"Teléfono/Celular","outlined":"","rules":[
                        value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
                    ]},model:{value:(_vm.dataMessage.phone),callback:function ($$v) {_vm.$set(_vm.dataMessage, "phone", $$v)},expression:"dataMessage.phone"}})],1)]),_c('div',{staticClass:"mt-2"},[_c('v-textarea',{attrs:{"label":"Solicitud / Comentario","height":"120","outlined":"","rules":[
                    value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
                ]},model:{value:(_vm.dataMessage.comment),callback:function ($$v) {_vm.$set(_vm.dataMessage, "comment", $$v)},expression:"dataMessage.comment"}})],1),_c('div',{staticClass:"mt-2"},[_c('v-text-field',{attrs:{"dense":"","label":"¿Cómo nos conocistes?","outlined":"","rules":[
                    value => !_vm.mRIsEmpty(value) || _vm.smsEmpty
                ]},model:{value:(_vm.dataMessage.info),callback:function ($$v) {_vm.$set(_vm.dataMessage, "info", $$v)},expression:"dataMessage.info"}})],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","ripple":false},on:{"click":function($event){return _vm.eValidateForm()}}},[_vm._v(" ENVIAR ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }