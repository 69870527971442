<template>
    <v-form
        lazy-validation
        v-model="valid"
        ref="formcontact"
        class="o-form-contact"
    >
        <div>
            <h1 class="app-public-big-title text-center mb-3">Pedir Presupuesto</h1>
            <div class="o-form-contact__fill">
                <div class="o-form-contact__fill-input">
                    <v-text-field
                        dense
                        label="Nombre"
                        outlined
                        v-model="dataMessage.name"
                        :rules="[
                            value => !mRIsEmpty(value) || smsEmpty
                        ]"
                    ></v-text-field>
                </div>
                <div class="o-form-contact__fill-input">
                    <v-text-field
                        dense
                        label="Asunto"
                        outlined
                        v-model="dataMessage.affair"
                        :rules="[
                            value => !mRIsEmpty(value) || smsEmpty
                        ]"
                    ></v-text-field>
                </div>
            </div>
            <div class="o-form-contact__fill mt-2">
                <div class="o-form-contact__fill-input">
                    <v-text-field
                        dense
                        label="Correo Electrónico"
                        outlined
                        v-model="dataMessage.e_mail"
                        :rules="[
                            value => !mRIsEmpty(value) || smsEmpty
                        ]"
                    ></v-text-field>
                </div>
                <div class="o-form-contact__fill-input">
                    <v-text-field
                        dense
                        label="Teléfono/Celular"
                        outlined
                        v-model="dataMessage.phone"
                        :rules="[
                            value => !mRIsEmpty(value) || smsEmpty
                        ]"
                    ></v-text-field>
                </div>
            </div>
            <div class="mt-2">
                <v-textarea
                    label="Solicitud / Comentario"
                    height="120"
                    outlined
                    v-model="dataMessage.comment"
                    :rules="[
                        value => !mRIsEmpty(value) || smsEmpty
                    ]"
                ></v-textarea>
            </div>
            <div class="mt-2">
                <v-text-field
                    dense
                    label="¿Cómo nos conocistes?"
                    outlined
                    v-model="dataMessage.info"
                    :rules="[
                        value => !mRIsEmpty(value) || smsEmpty
                    ]"
                ></v-text-field>
            </div>
            <div class="text-center">
                <v-btn
                    color="primary"
                    outlined
                    :ripple="false"
                    @click="eValidateForm()"
                >
                    ENVIAR
                </v-btn>
            </div>
        </div>
    </v-form>
</template>
<script>
import { rulesMixin } from '../../../mixins';
export default {
    mixins: [rulesMixin],
    props: {
        dataMessage: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formcontact.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formcontact.resetValidation();
        }
    }
}
</script>