import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Notice {
    constructor (
        title = null,
        description = null,
        file = null,
        name_photo = null,
        link_one = null,
        link_two = null
    ) {
        this.title = title;
        this.description = description;
        this.file = file;
        this.name_photo = name_photo;
        this.link_one = link_one;
        this.link_two = link_two;
    }
    static async getNotices(page, dateFrom, dateTo, enabled) {
        const response = await axios.get(
            `${apiUrls.NOTICE}/list?page=${page}&date_from=${dateFrom}&date_to=${dateTo}&enabled=${enabled}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addNotice(parameters) {
        const apitoken = JSON.parse(localStorage.getItem("algis")).token;
        const header = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Authorization": "Bearer " + apitoken,
        }
        const response = await axios.post(
            `${apiUrls.NOTICE}/add`,
            parameters,
            { headers: header }
        );
        return response.data;
    }
    static async editNotice(parameters) {
        const response = await axios.put(
            `${apiUrls.NOTICE}/update`,
            parameters,
            { headers: userStore.state.header }

        );
        return response.data;
    }
    static async changeState(parameters) {
        const response = await axios.patch(
            `${apiUrls.NOTICE}/change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteNotice(parameters) {
        const response = await axios.delete(
            `${apiUrls.NOTICE}/delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
    static async updatePhoto(parameters) {
        const apitoken = JSON.parse(localStorage.getItem("algis")).token;
        const header = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Authorization": "Bearer " + apitoken,
        }
        const response = await axios.post(
            `${apiUrls.NOTICE}/change-photo`,
            parameters,
            { headers: header }
        );
        return response.data;
    }
    static async getNoticesPublic(page) {
        const response = await axios.get(
            `${apiUrls.NOTICE}/list-public?page=${page}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getNotice(id, quantity) {
        const response = await axios.get(
            `${apiUrls.NOTICE}/public?id=${id}&quantity=${quantity}`
        );
        return response.data;
    }
    static async recommendations() {
        const response = await axios.get(
            `${apiUrls.NOTICE}/recommendations`
        );
        return response.data;
    }
}
export default Notice;