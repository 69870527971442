<template>
    <div class="o-card-user">
        <CFillInfoComp
            title="Nombre"
            :subtitle="user.name"
        />
        <CFillInfoComp
            title="Usuario"
            :subtitle="user.name"
        />
        <CFillInfoComp
            title="Estado"
            :subtitle="user.name"
        >
            <div
                :class="user.enabled == 1 ? 'user__success ml-1' : 'user__error ml-1'"
            ></div>
        </CFillInfoComp>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-2">
            <MBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <MBtnCardComp
                :icon="user.enabled == 1
                    ? 'mdi-eye-off'
                    : 'mdi-eye'"
                @click="$emit('clickstate')"
            />
            <MBtnCardComp
                icon="mdi-account-lock"
                @click="$emit('clickcredentials')"
            />
        </div>
    </div>
</template>
<script>
import {
    CFillInfoComp
} from "../../cells";
import {
    MBtnCardComp
} from "../../molecules";
export default {
    components: {
        CFillInfoComp,
        MBtnCardComp
    },
    props: {
        user: Object
    }
}
</script>