<template>
    <div class="d-flex justify-center align-center">
        <div class="mr-1">
            <CBtnCircleToolTipComp
                :disabled="!conditionBack"
                smsToolTip="Página Anterior"
                :isMobile="isMobile"
                @click="$emit('clickback')"
            >
                <v-icon color="white" small>mdi-chevron-left</v-icon>
            </CBtnCircleToolTipComp>
        </div>
        <CBtnCircleToolTipComp
            :disabled="!conditionGo"
            :isMobile="isMobile"
            smsToolTip="Página Siguiente"
            @click="$emit('clickgo')"
        >
            <v-icon color="white" small>mdi-chevron-right</v-icon>
        </CBtnCircleToolTipComp>
        <h1 class="app-private-title ml-2">{{titlePaginate}}</h1>
    </div>
</template>
<script>
import {
    CBtnCircleToolTipComp
} from "../cells";
export default {
    components: {
        CBtnCircleToolTipComp
    },
    props: {
        titlePaginate: String,
        conditionBack: Boolean,
        conditionGo: Boolean,
        isMobile: Boolean
    }
}
</script>
